import classNames from 'classnames';
import React from 'react';

export default function Card({
  children,
  backImage = null,
  frontImage = null,
  title = '',
  subTitle = null,
  titleClassNames = '',
  subTitleClassNames = '',
}) {
  return (
    <div className="flex flex-col items-center justify-between">
      {backImage ? (
        <div
          className="-mt-8  sm:-mt-20"
          style={{
            backgroundImage: backImage ? 'url(' + backImage + ')' : 'none',
            width: 175,
            height: 175,
            backgroundSize: 'contain',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ) : (
        <div className="mt-16" />
      )}
      <div className="card w-80 sm:w-96 bg-base-100 shadow-xl p-2 py-4 flex items-center">
        {frontImage && (
          <div
            className="-mt-24 sm:-mt-40"
            style={{
              backgroundImage: frontImage ? 'url(' + frontImage + ')' : 'none',
              width: 175,
              height: 175,
              backgroundSize: 'contain',
              backgroundPosition: 'center bottom',
              backgroundRepeat: 'no-repeat',
            }}
          />
        )}

        <div className="flex items-center justify-center flex-col prose text-center">
          <h2 className={classNames('', titleClassNames)}>{title}</h2>
          {subTitle && (
            <p className={classNames('-mt-4 mb-4', subTitleClassNames)}>
              {subTitle}
            </p>
          )}
        </div>

        {children}
      </div>
    </div>
  );
}
