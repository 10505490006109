import ReactGA from 'react-ga4';

export const sendGAEvent = (eventName, params) => {
  ReactGA.event(eventName, params);
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};
