import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoMailOutline, IoLockClosedOutline } from 'react-icons/io5';
import { langs } from '../i18n/config';
import { useUser } from '../contexts/UserContext';
import Input from '../components/Input';
import api from '../service/axiosInstance';
import StepsCounter from '../components/StepsCounter';
import AmdocsStepsCounter from '../components/AmdocsStepsCounter';
import Card from '../components/Card';
import LayoutBase from '../components/LayoutBase';
import Toast from '../components/Toast';
import { sendGAEvent, formatTimestamp } from '../analyticsEvents';

export default function Login() {
  const { t } = useTranslation('common');
  const [, setCookie] = useCookies();
  const { userData, setUserData } = useUser();

  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [errorMsgKey, setErrorMsgKey] = useState('');
  const [invalidInfo, setInvalidInfo] = useState(false);
  const [locked, setLocked] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [cookies] = useCookies();
  const codeCookie = cookies['couponCode'];
  const { lang } = useParams();
  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';

  const isPurchaseFlow = process.env.REACT_APP_FLOW_TYPE === 'purchase';
  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';
  const isAmdocsFlow = process.env.REACT_APP_FLOW_TYPE === 'amdocs';
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';

  const validateEmailOnChange = (e) => {
    setInvalidEmail(false);
    setInvalidInfo(false);
    const input = e.target.value.toLowerCase().replace(/[^0-9a-z@._+-]/g, '');
    e.target.value = input;
    setEmail(input);
  };

  const doLogin = () => {
    setLocked(true);
    const currentTimestamp = Date.now();
    api
      .post('/api/v1/sessions/login', {
        email,
        password,
      })
      .then(({ data }) => {
        const { access_token, refresh_token } = data;
        setCookie('accessToken', access_token);
        setCookie('refreshToken', refresh_token);

        setUserData({
          ...userData,
          email: email,
        });

        sendGAEvent('login_success', {
          timestamp: formatTimestamp(currentTimestamp),
          email: email,
        });

        if (isCheckoutFlow) {
          navigate('/checkout');
        } else if (isPurchaseFlow) {
          navigate('/purchase');
        } else if (isAmdocsFlow) {
          return validateAndContinueAmdocsFlow();
        } else {
          navigate('/redeem');
        }
      })
      .catch(() => {
        setInvalidInfo(true);
        setErrorMsgKey('incorrect_email_or_password');
      })
      .finally(() => {
        setLocked(false);
      });
  };

  function activateSubscription() {
    const redeemptionCode = codeCookie || 'code_not_found';
    api
      .post('/api/v1/subscriptions/market_one/activate_subscription', {
        redemption_code: redeemptionCode,
      })
      .then(() => {
        const successMessage = [
          { msg: t('subscription_successfully_activated'), type: 'success' },
        ];
        setToasts((prevState) => prevState.concat(successMessage));
        navigate(prefix + '/download');
      })
      .catch((error) => {
        const status = error.response?.status;
        if (status === 404) {
          setToasts((state) =>
            state.concat([
              { msg: 'Invalid Coupon Code Provided', type: 'error' },
            ])
          );
        } else if (status === 400) {
          setToasts((state) =>
            state.concat([
              { msg: 'Expired Coupon Code Provided', type: 'error' },
            ])
          );
        } else {
          const detail = error.response?.data.detail || error.message;
          let details = [];
          if (Array.isArray(detail)) {
            details = detail.map((item) => {
              const loc = item.loc[-1];
              const msg = item.msg;
              return { msg: t(`${loc}: ${msg}`), type: 'error' };
            });
          } else {
            details.push({ msg: t(detail), type: 'error' });
          }
          setToasts((state) => state.concat(details));
        }
      })
      .finally(() => setLocked(false));
  }

  function validateAndContinueAmdocsFlow() {
    return api
      .get('/api/v1/subscriptions/status')
      .then(({ data }) => {
        return data.status === 'active' || data.status === 'trialing';
      })
      .then((hasActiveOrTrialSubscription) => {
        if (!hasActiveOrTrialSubscription) {
          return activateSubscription();
        } else {
          setInvalidInfo(true);
          setErrorMsgKey('cancel_subscription_first');
        }
      });
  }

  return (
    <LayoutBase
      bgImage={isShahidFlow ? '' : '/images/bg.png'}
      bgColorClass={isShahidFlow ? 'bg-base-content' : ''}
    >
      <div className="flex flex-col items-center justify-between mb-20">
        <Card
          backImage={isShahidFlow ? '' : '/images/chars/bunny.png'}
          title={t('welcome_back')}
          titleClassNames={`w-full text-2xl font-extrabold`}
          subTitle={
            !isPurchaseFlow && (
              <span className="sub-title">
                {isAmdocsFlow ? t('login_to_activate') : t('login_to_redeem')}
              </span>
            )
          }
        >
          <div
            className={`form-control w-full relative mb-3 ${
              isPurchaseFlow ? 'mt-3' : ''
            }`}
          >
            <Input
              type="email"
              iconLeftBool={true}
              iconLeft={IoMailOutline}
              iconRightBool={invalidEmail || invalidInfo}
              placeholder={t('email_placeholder')}
              iconClassError={invalidEmail || invalidInfo}
              onChange={validateEmailOnChange}
              onBlur={(e) => {
                const input = e.target.value;

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const validEmail = emailRegex.test(input);

                if (!validEmail) {
                  setInvalidEmail(true);
                } else if (validEmail) {
                  setInvalidEmail(false);
                  setInvalidInfo(false);
                }
              }}
            />
          </div>
          <div className="relative w-full mb-3 form-control">
            <Input
              type="password"
              iconLeftBool={true}
              iconLeft={IoLockClosedOutline}
              iconRightBool={true}
              iconClassError={invalidInfo}
              placeholder={t('sign_password_placeholder')}
              onChange={(e) => {
                setPassword(e.target.value);
                setInvalidInfo(false);
              }}
            />
          </div>
          {(invalidEmail || invalidInfo) && (
            <div className="text-xs font-medium text-center text-red-500">
              <span>
                {invalidEmail && t('invalid_email')}
                {invalidInfo && t(errorMsgKey)}
              </span>
            </div>
          )}
          <div className="flex flex-col items-center justify-center w-full px-6">
            <Link
              to={'/forgot'}
              className={
                'link link-hover text-sm font-bold uppercase mt-2 mb-2 ' +
                (isShahidFlow ? 'link-accent' : 'link-primary')
              }
            >
              {t('forgot_my_password')}
            </Link>
            <button
              className={
                'w-full btn rounded-full m-2 uppercase text-white text-lg font-extrabold ' +
                (isShahidFlow
                  ? 'bg-gradient-to-r from-secondary via-tertiary to-primary text-white hover:bg-blend-darken'
                  : 'btn-primary')
              }
              onClick={doLogin}
              disabled={locked}
            >
              {locked ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                t('login_btn')
              )}
            </button>

            <div className="flex items-center w-full">
              <Link
                to={'/'}
                className="w-full mb-2 uppercase rounded-full btn btn-primary btn-outline text-md"
              >
                {t('go_back')}
              </Link>
            </div>
          </div>
        </Card>

        {isAmdocsFlow ? <AmdocsStepsCounter /> : <StepsCounter />}

        <div className="z-50 toast toast-top toast-end">
          {toasts.map(({ msg, type }, index) => (
            <Toast
              msg={msg}
              type={type}
              key={msg + index}
              index={index}
              total={toasts.length}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
}
