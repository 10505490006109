import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { langs } from '../i18n/config';
import { useEffect } from 'react';
import api from '../service/axiosInstance';
import _ from 'lodash';
import {
  PaymentElement,
  useElements,
  useStripe,
  AddressElement,
} from '@stripe/react-stripe-js';
import { useCookies } from 'react-cookie';
import {
  IoArrowForwardOutline,
  IoPencilOutline,
  IoCheckmark,
  IoPhonePortraitOutline,
  IoTabletPortraitOutline,
  IoTvOutline,
} from 'react-icons/io5';
import * as RadioGroup from '@radix-ui/react-radio-group';
import Toast from '../components/Toast';

const REACT_APP_PRICE_ID_MONTH_LG = {
  PROD: 'price_1QrjrJJ8WI6YLnTtkIF8wTkS',
  QA: 'price_1QmehfJ8WI6YLnTtPdpOHwBX'
};
const REACT_APP_PRICE_ID_YEAR_LG = {
  PROD: 'price_1Qrk9TJ8WI6YLnTtRElpPyJn',
  QA: 'price_1OxCqzJ8WI6YLnTtJGK4TbaI'
}

export default function PlanCheckoutForm() {
  const { t, i18n } = useTranslation('common');
  const [cookies, , removeCookie] = useCookies();

  const { lang } = useParams();

  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const [toasts, setToasts] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [childName, setChildName] = useState('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [isPricesLoaded, setIsPricesLoaded] = useState(false);

  const [billingFrequency, setBillingFrequency] = useState('month');

  const isProduction = process.env.REACT_APP_ENV === 'production';
  const environment = isProduction ? 'PROD' : 'QA';
  
  const [partner, setPartner] = useState(cookies["partner"]);
  const isLG = partner === "lg";

  const priceIds = isLG
  ? [
      REACT_APP_PRICE_ID_MONTH_LG[environment],
      REACT_APP_PRICE_ID_YEAR_LG[environment],
    ]
  : [
      process.env[`REACT_APP_PRICE_ID_MONTH_${environment}`],
      process.env[`REACT_APP_PRICE_ID_YEAR_${environment}`],
    ];

  const [priceId, setPriceId] = useState(priceIds[0]);
  const [currency, setCurrency] = useState("usd");

  const [formData, setFormData] = useState({
    childName: '',
    yearOfBirth: '',
    paymentInfo: null,
    billingAddress: null,
  });

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [nextBtnLocked, setNextBtnLocked] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);

  const [prices, setPrices] = useState([]);

  function grabPartner() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === 'partner') {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  }

  useEffect(() => {
    const partner = grabPartner()
    setPartner(partner);
  }, []);

  useEffect(() => {
    if (priceIds && priceIds.length > 0 && !isPricesLoaded) {
      Promise.all(
        priceIds.map((priceId) =>
          api.get('/api/v1/subscriptions/stripe/price?price_id=' + priceId)
        )
      )
        .then((responses) => {
          const priceData = responses.map(({ data }) => ({
            priceId: data['stripe_price_id'],
            billingFrequency: data['billing_frequency'] ?? 'month',
            currencyAmount: (data['currency_amount'] ?? 0) / 100,
            currencyName: data['currency_name'] ?? 'USD',
          }));
          setPrices(priceData);
          setIsPricesLoaded(true);
          setCurrency(priceData[0].currencyName)
        })
        .catch((error) => {
          console.error('Error fetching price data:', error);
        });
    }
  }, [priceIds, isPricesLoaded]);

  const handleCheckout = () => {
    if (!stripe || !elements || !allStepsCompleted) return;
    setNextBtnLocked(true);

    const errorToasts = (error) => {
      setNextBtnLocked(false);

      const data = error.response?.data || error;

      if (data.detail) {
        const detail = data.detail;
        let details = [];

        if (_.isArray(detail)) {
          details = _.map(detail, (item) => {
            const loc = _.last(item.loc);
            const msg = item.msg;
            return { msg: t(`${loc}: ${msg}`), type: 'error' };
          });
        } else {
          details = [{ msg: t(detail), type: 'error' }];
        }

        setToasts((state) => state.concat(details));
      } else if (data.message) {
        setToasts((state) =>
          state.concat([{ msg: t(data.message), type: 'error' }])
        );
      }
    };

    const stripeConfirmSetup = () => {
      stripe
        .confirmSetup({
          elements,
          redirect: 'if_required',
        })
        .then(({ setupIntent, error }) => {
          if (setupIntent) {
            subscriptionsSetupConfirm(setupIntent.id);
          }
          if (error) {
            errorToasts({
              response: { data: { message: error.message } },
            });
          }
        })
        .catch((e) => {
          console.log({ e });
        });
    };

    const subscriptionsSetupConfirm = async (setupIntentId) => {
      await api
        .post('/api/v1/subscriptions/stripe/setup/confirm', {
          setup_intent_id: setupIntentId,
        })
        .catch((error) => {
          errorToasts(error);
        });
      subscribePlan();
    };

    const subscribePlan = () => {
      api
        .post('/api/v1/subscriptions/stripe/subscribe', {
          stripe_price_id: priceId,
          currency: currency
        })
        .then(() => {
          const message = t('place_order_success_plan');
          setToasts((state) =>
            state.concat([{ msg: message, type: 'success' }])
          );
          removeCookie('setupIntentSecret');

          const completionUrl = prefix + '/download';
          navigate(completionUrl);
        })
        .catch((error) => {
          errorToasts(error);
        });
    };

    stripeConfirmSetup();
  };

  function formatCurrency(currencyAmount, currency) {
    return currency.toUpperCase() + ' ' + currencyAmount;
  }
  
  const allStepsCompleted = completedSteps.length === 3;

  const isButtonDisabled = nextBtnLocked || !allStepsCompleted;

  const updateCompletedSteps = (step) => {
    setCompletedSteps((prev) => {
      if (prev.includes(step)) {
        return prev.filter((s) => s !== step);
      } else {
        return [...prev, step];
      }
    });
  };

  const goToStep = (step) => {
    if (completedSteps.includes(step) || step === currentStep) {
      setCurrentStep(step);
    }
  };

  const handleContinue = () => {
    if (!completedSteps.includes(currentStep)) {
      setCompletedSteps((prev) => [...prev, currentStep]);
    }
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const isEditable = (step) => completedSteps.includes(step);

  return (
    <div className="justify-center w-full px-2 md:flex lg:flex-1">
      <div className="flex flex-col justify-center lg:flex-row lg:space-x-8 bg-light-pink">
        <aside className="items-center justify-center flex-1 p-4 space-y-6">
          <div
            className={`p-4 rounded-lg ${
              isEditable(1) || currentStep === 1
                ? 'bg-white shadow-md'
                : 'bg-custom-light-gray shadow-md'
            }`}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <div
                  className={`flex items-center justify-center w-8 h-8 rounded-full ${
                    isEditable(1)
                      ? 'bg-green-800 text-white'
                      : currentStep === 1
                        ? 'bg-primary text-white'
                        : 'bg-gray-400 text-white'
                  }`}
                >
                  {isEditable(1) ? (
                    <IoCheckmark className="w-6 h-6" />
                  ) : (
                    '1'
                  )}
                </div>
                <h2 className="ml-2 text-xl font-bold text-dark-purple-text">
                  {t('child_profile')}
                </h2>
              </div>
              {isEditable(1) && (
                <button
                  className="flex items-center text-sm text-green-700"
                  onClick={() => goToStep(1)}
                >
                  <IoPencilOutline className="mr-1 text-green-700" />
                  {t('edit')}
                </button>
              )}
            </div>
            {currentStep === 1 ? (
              <>
                <p className="mb-1 text-sm text-gray-500">
                  {t('child_profile_description')}
                </p>
                <label className="block mb-2 text-sm font-bold">
                  {t('child_first_name_optional')}
                </label>
                <input
                  type="text"
                  placeholder={t('junior')}
                  className="w-full px-4 py-2 mb-4 border rounded-lg"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                />
                <label className="block mb-2 text-sm font-bold">
                  {t('year_of_birth')}
                </label>
                <select
                  className="w-full px-4 py-2 mb-4 text-gray-500 border rounded-lg"
                  value={yearOfBirth}
                  onChange={(e) => setYearOfBirth(e.target.value)}
                >
                  <option value="" disabled>
                    {t('select_one')}
                  </option>
                  {Array.from(
                    { length: 15 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <button
                  className={`w-full rounded-full btn ${
                    yearOfBirth
                      ? 'btn-neutral text-custom-purple'
                      : 'bg-gray-400 text-gray-600 cursor-not-allowed'
                  }`}
                  onClick={handleContinue}
                  disabled={!yearOfBirth}
                >
                  {t('continue')}{' '}
                  <IoArrowForwardOutline className="inline ml-2" />
                </button>
              </>
            ) : isEditable(1) ? (
              <p className="text-sm text-gray-500">
                {childName}, {yearOfBirth}
              </p>
            ) : (
              <p className="text-sm text-gray-500">
                {t('complete_step_first')}
              </p>
            )}
          </div>
          <div
            className={`p-4 rounded-lg ${
              isEditable(2) || currentStep === 2
                ? 'bg-white shadow-md'
                : 'bg-custom-light-gray shadow-md'
            }`}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <div
                  className={`flex items-center justify-center w-8 h-8 rounded-full ${
                    isEditable(2)
                      ? 'bg-green-800 text-white'
                      : currentStep === 2
                        ? 'bg-primary text-white'
                        : 'bg-gray-400 text-white'
                  }`}
                >
                  {isEditable(2) ? (
                    <IoCheckmark className="text-lg" />
                  ) : (
                    '2'
                  )}
                </div>
                <h2 className="ml-2 text-xl font-bold text-dark-purple-text">
                  {t('billing_address')}
                </h2>
              </div>
              {isEditable(2) && (
                <button
                  className="flex items-center text-green-700"
                  onClick={() => goToStep(2)}
                >
                  <IoPencilOutline className="mr-1 text-green-700" />
                  {t('edit')}
                </button>
              )}
            </div>
            {currentStep === 2 ? (
              <div className="flex flex-col justify-center w-full">
                <p className="mb-4 text-sm text-red-500">{t('mandatory')}</p>
                <AddressElement
                  options={{ mode: 'billing' }}
                  onChange={(event) => {
                    if (event.complete) {
                      setFormData((prev) => ({
                        ...prev,
                        billingAddress: event.value.address,
                      }));
                    }
                  }}
                />
                <button
                  className={`flex items-center rounded-full justify-center w-full mt-4 btn btn-light-pink`}
                  onClick={handleContinue}
                  disabled={!elements || !stripe}
                >
                  {t('continue')}{' '}
                  <IoArrowForwardOutline className="inline ml-2" />
                </button>
              </div>
            ) : isEditable(2) ? (
              <div>
                <p className="text-sm text-gray-700">
                  {formData.billingAddress?.line1},{' '}
                  {formData.billingAddress?.city},{' '}
                  {formData.billingAddress?.postal_code},{' '}
                  {formData.billingAddress?.country}
                </p>
              </div>
            ) : (
              <p className="text-sm text-gray-500">
                {t('complete_step_first')}
              </p>
            )}
          </div>
          <div
            className={`p-4 rounded-lg ${
              isEditable(3) || currentStep === 3
                ? 'bg-white shadow-md'
                : 'bg-custom-light-gray shadow-md'
            }`}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <div
                  className={`flex items-center justify-center w-8 h-8 rounded-full ${
                    isEditable(3)
                      ? 'bg-green-800 text-white'
                      : currentStep === 3
                        ? 'bg-primary text-white'
                        : 'bg-gray-400 text-white'
                  }`}
                >
                  {isEditable(3) ? (
                    <IoCheckmark className="w-6 h-6" />
                  ) : (
                    '3'
                  )}
                </div>
                <h2 className="ml-2 text-xl font-bold text-dark-purple-text">
                  {t('payment_only')}
                </h2>
              </div>
            </div>
            {currentStep === 3 || isEditable(3) ? (
              <>
                <PaymentElement />
                <button
                  className={`flex items-center rounded-full justify-center w-full mt-4 btn btn-light-pink`}
                  onClick={() => updateCompletedSteps(currentStep)}
                  disabled={!elements || !stripe}
                >
                  {t('continue')}{' '}
                  <IoArrowForwardOutline className="inline ml-2" />
                </button>
              </>
            ) : (
              <p className="text-sm text-gray-500">
                {t('complete_steps_first')}
              </p>
            )}
          </div>
        </aside>

        <aside className="items-center flex-1 p-4 md:w-[600px]">
          <div className="w-full p-4 rounded-lg shadow-md bg-custom-light-gray">
            <h2 className="mb-3 text-lg font-bold text-dark-purple-text">
              {t('subscription_summary')}
            </h2>
            <div className="flex flex-col items-center ">
              <div className="w-full max-w-md pb-4 bg-white rounded-lg">
                <div className="flex items-center justify-between p-2 border-b-4 border-light-pink">
                  <img
                    src="/images/playkids-logo-black.png"
                    alt="PK+"
                    className="w-auto h-4 my-4"
                  />
                  <div className="flex space-x-2">
                    <IoPhonePortraitOutline className="w-5 h-5 text-dark-purple-text" />
                    <IoTabletPortraitOutline className="w-5 h-5 text-dark-purple-text" />
                    <IoTvOutline className="w-5 h-5 text-dark-purple-text" />
                  </div>
                </div>
                <RadioGroup.Root
                  className="flex flex-col mt-3"
                  value={billingFrequency}
                  onValueChange={(value) => {
                    setBillingFrequency(value);
                    setPriceId(value === 'year' ? priceIds[0] : priceIds[1]);
                    setCurrency(prices.find((price) => price.priceId = priceId).currencyName);
                  }}
                >
                  {prices.map((price, index) => (
                    <RadioGroup.Item
                      key={index}
                      value={price.billingFrequency}
                      className={`flex items-center p-4 w-full ${
                        billingFrequency === price.billingFrequency
                          ? 'bg-neutral border-primary'
                          : 'bg-white border-gray-300'
                      }`}
                    >
                      <div className="flex items-center mr-4">
                        <div
                          className={`w-6 h-6 flex items-center justify-center rounded-full border-2 ${
                            billingFrequency === price.billingFrequency
                              ? 'border-primary bg-primary'
                              : 'border-dark-purple-text bg-white'
                          }`}
                        >
                          {billingFrequency === price.billingFrequency && (
                            <div className="w-3 h-3 bg-white rounded-full" />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                          <h4 className="text-sm font-medium text-dark-purple-text">
                            {price.billingFrequency === 'year'
                              ? t('annual')
                              : t('monthly')}
                          </h4>
                        </div>
                        <span className="flex items-center p-0 m-0 text-lg font-bold text-dark-purple-text whitespace-nowrap">
                          {formatCurrency(
                            price.currencyAmount,
                            price.currencyName
                          )}
                          <span className="p-0 m-0 text-sm">
                            /{t(price.billingFrequency)}
                          </span>
                        </span>
                        {price.billingFrequency === 'year' && (
                          <p className="text-xs text-gray-500 text-start">
                            {t('one_payment_of')}{' '}
                            {formatCurrency(
                              price.currencyAmount,
                              price.currencyName
                            )}
                          </p>
                        )}
                        <p className="text-xs text-gray-500 text-start">
                          {t('free_3_day_trial')}
                        </p>
                      </div>
                    </RadioGroup.Item>
                  ))}
                </RadioGroup.Root>
              </div>
              <button
                className={`mt-6 w-full py-2 px-4 text-md font-bold rounded-full ${
                  isButtonDisabled
                    ? 'bg-[rgba(66,30,64,0.12)] text-gray-600 cursor-not-allowed'
                    : 'bg-primary text-white'
                }`}
                disabled={isButtonDisabled}
                onClick={handleCheckout}
              >
                {t('confirm_subscription')}
              </button>
              <p className="mt-2 text-sm font-semibold text-center text-dark-purple-text">
                {t('secure_checkout')}
              </p>
            </div>
          </div>
        </aside>
      </div>

      <div className="z-50 toast toast-top toast-end">
        {_.map(toasts, ({ msg, type }, index) => (
          <Toast
            msg={msg}
            type={type}
            key={msg + index}
            index={index}
            total={toasts.length}
          />
        ))}
      </div>
    </div>
  );
}
