import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './lang/en.json';
import pt from './lang/pt.json';
import fr from './lang/fr.json';
import ar from './lang/ar.json';
import zz from './lang/zz.json';

export const langs = [
  {
    code: 'EN',
    name: 'English',
  },
  {
    code: 'PT',
    name: 'Português',
  },
  {
    code: 'FR',
    name: 'Français',
  },
  {
    code: 'AR',
    name: 'اَلْعَرَبِيَّةُ',
  },
];

if (process.env.REACT_APP_ENV != 'production') {
  langs.push({ code: 'ZZ', name: 'Test' });
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: ['spa', 'homepage', 'login', 'signup', 'redeem', 'footer'],
    defaultNS: 'spa',
    resources: {
      en,
      pt,
      fr,
      ar,
      zz,
    },
    supportedLngs: langs.map((lang) => lang.code.toLowerCase()),
    fallbackLng: 'en',
    fallbackNS: 'common',
    fallbackToDefaultNS: true,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export { i18n };
