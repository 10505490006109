import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import LayoutBase from '../../components/LayoutBase';
import CheckoutFlow from './CheckoutFlow';
import AmdocsFlow from './AmdocsFlow';
import PurchaseFlow from './PurchaseFlow';
import DefaultFlow from './DefaultFlow';
import AmdocsStepsCounter from '../../components/AmdocsStepsCounter';
import StepsCounter from '../../components/StepsCounter';
import { langs } from '../../i18n/config';
import api from '../../service/axiosInstance';

export default function HomePage() {
  const { t } = useTranslation('homepage');
  const { lang } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [searchParams] = useSearchParams();
  const [isLoginAttempted, setIsLoginAttempted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [magicTokenError, setMagicTokenError] = useState('');
  const [noToken, setNoToken] = useState(false);
  const [couponValid, setCouponValid] = useState(false);
  const [magicTokenValid, setMagicTokenValid] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorSubTitle, setErrorSubTitle] = useState('');

  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';
  const isPurchaseFlow = process.env.REACT_APP_FLOW_TYPE === 'purchase';
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';
  const isAmdocsFlow = process.env.REACT_APP_FLOW_TYPE === 'amdocs';
  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';

  const bgImage = (() => {
    if (isCheckoutFlow && !magicTokenValid && !noToken) {
      return '/images/bg-blue-new.png';
    }
    if (isShahidFlow) {
      return '';
    }
    return '/images/bg.png';
  })();

  const handleLoading = () => {
    setIsLoading(false);
  };

  const codeCookie = cookies['couponCode'];
  const magicToken = cookies['magicToken'];
  const partner = cookies['partner'];
  console.log('partner', partner);

  useEffect(() => {
    const code = searchParams.get('code');
    const hasCode = searchParams.has('code');
    const codeCookie = cookies['couponCode'];
    if (code !== codeCookie) {
      if (hasCode) {
        setCookie('couponCode', code);
        setIsLoading(false);
      } else {
        setCookie('couponCode', null);
      }
    }
    const priceId = searchParams.get('price_id');
    const hasPriceId = searchParams.has('price_id');
    if (hasPriceId) {
      setCookie('price_id', priceId);
    } else {
      const isProd = process.env.REACT_APP_ENV === 'production';
      const defaultPriceId = isProd
        ? process.env.REACT_APP_PRICE_ID_MONTH_PROD
        : process.env.REACT_APP_PRICE_ID_MONTH_QA;
      setCookie('price_id', defaultPriceId);
    }
  }, []);

  useEffect(() => {
    if (isAmdocsFlow) {
      const amdocsCode = grabCouponCode();

      if (amdocsCode !== codeCookie) {
        if (amdocsCode) {
          setCookie('couponCode', amdocsCode);
          setIsLoading(false);
        } else {
          setCookie('couponCode', null);
        }
      }

      validateCouponCode(amdocsCode);
    } else if (isCheckoutFlow) {
      const partner = grabPartner();
      setCookie('partner', partner);
      const loginToken = grabMagicToken();
      if (loginToken !== magicToken && !isLoginAttempted) {
        if (loginToken) {
          setCookie('magicToken', loginToken);
          setIsLoading(false);
        } else {
          setCookie('magicToken', null);
        }
      }

      if (!magicTokenValid) {
        validateAndLoginWithMagicToken(loginToken);
      }
    }
  }, [isAmdocsFlow, isCheckoutFlow, codeCookie, magicToken]);

  function grabCouponCode() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === 'code') {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  }

  function grabMagicToken() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === 'token') {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  }

  function grabPartner() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === 'partner') {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  }

  function validateCouponCode(code) {
    if (!code) {
      setErrorTitle('invalid_coupon');
      setErrorSubTitle('try_again_invalid_coupon');
      setCouponValid(false);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const redeemptionCode = code;

    api
      .post(
        `/api/v1/subscriptions/market_one/validate_code?redemption_code=${redeemptionCode}`,
        {}
      )
      .then(() => {
        setCouponValid(true);
        setIsLoading(false);
      })
      .catch((error) => {
        const detail = error.response?.data.detail || error.message;
        handleLoading();
        if (detail === 'INVALID_CODE') {
          setErrorTitle('invalid_coupon');
          setErrorSubTitle('try_again_invalid_coupon');
        } else if (detail === 'EXPIRED_CODE') {
          setErrorTitle('expired_coupon');
          setErrorSubTitle('try_again_expired_coupon');
        } else if (detail === 'INVALID_STATE') {
          setErrorTitle('already_used_coupon');
          setErrorSubTitle('try_again_already_used_coupon');
        } else {
          setErrorTitle('invalid_coupon');
          setErrorSubTitle('try_again_invalid_coupon');
        }
      });
  }

  function validateAndLoginWithMagicToken(token) {
    if (!token) {
      setNoToken(true);
      setIsLoading(false);
      return;
    }

    if (isLoginAttempted) return;

    setIsLoginAttempted(true);
    setIsLoading(true);

    api
      .post(`/api/v1/sessions/login/magic_token?token=${token}`, {})
      .then(({ data }) => {
        setCookie('accessToken', data.access_token);
        setCookie('refreshToken', data.refresh_token);
        setMagicTokenValid(true);
        getUserDetails();
        setIsLoading(false);
      })
      .catch((error) => {
        removeCookie('accessToken');
        removeCookie('refreshToken');

        const status = error.response?.status;
        if (status === 403) {
          setErrorTitle('invalid_token');
          setErrorSubTitle('try_again_invalid_coupon');
        } else if (status === 404) {
          setErrorTitle('expired_token');
          setErrorSubTitle('try_again_expired_coupon');
        } else if (status === 500) {
          setMagicTokenError(t('server_error'));
        } else {
          setMagicTokenError(t('unknown_error'));
        }

        console.error(`Login failed with status ${status}:`, error.message);
        setMagicTokenValid(false);
        setIsLoading(false);
        setIsLoginAttempted(false);
      });
  }

  function getUserDetails() {
    api
      .get('/api/v1/user', {})
      .then(({ data }) => setUserEmail(data?.credentials[0]?.email))
      .catch(() => setUserEmail(''));
  }

  function getButtonClass(outline) {
    return `btn btn-wide rounded-full m-2 uppercase ${
      isShahidFlow
        ? 'bg-gradient-to-r from-secondary via-tertiary to-primary text-white hover:bg-blend-darken'
        : `btn-primary ${outline ? 'btn-outline text-primary' : 'text-white'}`
    }`;
  }

  function renderContent() {
    if (isCheckoutFlow) {
      return (
        <CheckoutFlow
          magicTokenValid={magicTokenValid}
          magicTokenError={magicTokenError}
          userEmail={userEmail}
          prefix={prefix}
          t={t}
          getButtonClass={getButtonClass}
          errorTitle={errorTitle}
          errorSubTitle={errorSubTitle}
          isLoading={isLoading}
          noToken={noToken}
        />
      );
    }

    if (isAmdocsFlow) {
      return (
        <AmdocsFlow
          couponValid={couponValid}
          isLoading={isLoading}
          t={t}
          prefix={prefix}
          getButtonClass={getButtonClass}
          errorTitle={errorTitle}
          errorSubTitle={errorSubTitle}
        />
      );
    }

    if (isPurchaseFlow) {
      return (
        <PurchaseFlow t={t} prefix={prefix} getButtonClass={getButtonClass} />
      );
    }

    return <DefaultFlow t={t} getButtonClass={getButtonClass} />;
  }

  return (
    <LayoutBase
      bgImage={bgImage}
      bgColorClass={isShahidFlow ? 'bg-base-content' : ''}
    >
      <div className="flex flex-col items-center">
        {renderContent()}
        {isAmdocsFlow && couponValid ? <AmdocsStepsCounter /> : ''}
        {!isAmdocsFlow && !isCheckoutFlow ? <StepsCounter /> : ''}
      </div>
    </LayoutBase>
  );
}
