import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import StepsCounter from '../components/StepsCounter';
import AmdocsStepsCounter from '../components/AmdocsStepsCounter';
import LayoutBase from '../components/LayoutBase';
import Card from '../components/Card';

export default function Download() {
  const { t, i18n } = useTranslation('common');
  const { lang } = useParams();

  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';
  const isAmdocsFlow = process.env.REACT_APP_FLOW_TYPE === 'amdocs';

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const [cookies, removeCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies['accessToken']) {
      navigate('/');
    }

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBackButtonPress);

    function handleBackButtonPress() {
      removeCookie('accessToken');
      removeCookie('refreshToken');
      navigate('/');
    }

    return () => {
      window.removeEventListener('popstate', handleBackButtonPress);
    };
  }, [cookies, navigate]);

  const cardSubtitle = (
    <span>
      {t('download')} <strong>{t('app_name')}</strong>
      <br /> {t('enjoy')}
    </span>
  );

  const bgImage = '/images/bg.png';

  const cardTitle = t('welcome');

  const cardSubtitleText = cardSubtitle;

  return (
    <LayoutBase bgImage={bgImage}>
      <div
        className={`flex flex-col items-center justify-between w-full mb-32 ${isCheckoutFlow ? 'mt-36' : ''}`}
      >
        <Card
          frontImage='/images/chars/dog.png'
          backImage=''
          title={cardTitle}
          titleClassNames={`w-full text-2xl font-extrabold ${isShahidFlow ? 'text-accent' : 'text-primary'} ${isCheckoutFlow ? 'text-2xl mt-4' : 'text-4xl'}`}
          subTitle={cardSubtitleText}
          subTitleClassNames={`font-normal text-black ${isCheckoutFlow ? 'text-md mb-2' : 'text-xl mt-4'}`}
        >
        </Card>
        <div className="flex flex-row items-center justify-between mt-8">
              <a
                href="https://play.google.com/store/apps/details?id=com.movile.playkids"
                className="mx-2"
              >
                <img
                  src={t('download_android')}
                  width={128}
                  height={44}
                  alt="google store"
                />
              </a>
              <a
                href="https://apps.apple.com/br/app/id613398383"
                className="mx-2"
              >
                <img
                  src={t('download_ios')}
                  width={128}
                  height={44}
                  alt="apple store"
                />
              </a>
            </div>

        {!isCheckoutFlow &&
          (isAmdocsFlow ? (
            <AmdocsStepsCounter position={2} />
          ) : (
            <StepsCounter position={4} />
          ))}
      </div>
    </LayoutBase>
  );
}