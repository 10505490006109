import axios from 'axios';
import { i18n } from '../i18n/config';

const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PRODUCTION_API
      : process.env.REACT_APP_STAGING_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const accessToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)accessToken\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  config.headers['x-language'] = i18n.language;

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const refreshToken = document.cookie.replace(
          /(?:(?:^|.*;\s*)refreshToken\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        );

        try {
          const response = await api.post('/api/v1/sessions/refresh', {
            refresh_token: refreshToken,
          });
          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token;

          document.cookie = `accessToken=${newAccessToken}`;
          document.cookie = `refreshToken=${newRefreshToken}`;

          error.config.headers.Authorization = `Bearer ${newAccessToken}`;

          if (process.env.REACT_APP_FLOW_TYPE === 'purchase') {
            window.location.href = '/';
          }

          return api(error.config);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    } else if (error.request) {
      console.error('Error in the request response:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }
    console.error('Error configuration:', error.config);

    return Promise.reject(error);
  }
);

export default api;
