import React from 'react';
import Card from '../../components/Card';
import SpinnerLoading from '../../components/SpinnerLoading';
import { Link } from 'react-router-dom';
import { IoWarning } from 'react-icons/io5';
import DefaultFlow from './DefaultFlow';
import { useTranslation } from 'react-i18next';

export default function CheckoutFlow({
  isLoading,
  magicTokenValid,
  noToken,
  userEmail,
  prefix,
  getButtonClass,
  errorTitle,
  errorSubTitle,
}) {
  const { t } = useTranslation('homepage');

  if (isLoading) {
    return <SpinnerLoading />;
  }

  if (noToken) {
    return (
      <DefaultFlow prefix={prefix} getButtonClass={getButtonClass} />
    );
  }

  if (magicTokenValid) {
    return (
      <Card
        title={
          <>
            {'You are signing up as: '}
            <div className="flex items-center justify-center w-full h-full gap-2 px-3 py-2 bg-pink-200 rounded-md bg-opacity-30">
              <div className="text-sm font-bold text-center text-gray-600 break-words font-nunito">
                {userEmail}
              </div>
            </div>
          </>
        }
        titleClassNames="w-2/3 text-lg font-extrabold"
        subTitle={
          <span className="text-sm sub-title">
            {
              'To use a different email address, please return to your TV app and create a new account.'
            }
          </span>
        }
      >
        <Link
          className="flex items-center justify-center w-full"
          to={`${prefix}/checkout`}
        >
          <button className={getButtonClass()}>
            {'Continue with this account'}
          </button>
        </Link>
      </Card>
    );
  }

  return (
    <Card
      title={
        <div className="flex flex-col items-center">
          <IoWarning className="w-12 h-12 text-red-500" />{' '}
          <span className="text-4xl font-bold text-red-500">
            {t(errorTitle)}
          </span>
        </div>
      }
      titleClassNames="w-4/3 py-3 px-8"
      subTitle={
        !isLoading && <span className="sub-title">{t(`${errorSubTitle}`)}</span>
      }
    />
  );
}
