import React from 'react';
import clsx from 'clsx';

import LanguageSelector from './LanguageSelector';

import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation('common');

  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';

  let logo;
  if (isShahidFlow) {
    logo = (
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet="/images/playkids-shahid-logo-full.png"
        />
        <img
          className="w-auto h-8"
          src="/images/playkids-shahid-logo-minimal.png"
          alt="PK+ x Shahid"
        />
      </picture>
    );
  } else {
    logo = (
      <img
        src="/images/playkids-logo-black.png"
        alt="PK+"
        className="w-auto h-5 md:h-9 lg:h-9"
        width={700}
        height={186}
      />
    );
  }

  return (
    <nav className="justify-between bg-white navbar">
      <a href="#">{logo}</a>
      <div className="block w-auto">
        <span>
          <LanguageSelector />
          <a
            href={t('help_center_link')}
            target="_blank"
            rel="noreferrer"
            className={clsx({
              'link link-hover ml-2 font-semibold': true,
              'link-primary': isShahidFlow,
              'text-red-400 hover:text-red-800': !isShahidFlow,
            })}
          >
            {t('help_center')}
          </a>
        </span>
      </div>
    </nav>
  );
};

export default Header;
