import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { IoMailOutline, IoInformationCircle } from 'react-icons/io5';
import { useCookies } from 'react-cookie';
import _ from 'lodash';

import { useUser } from '../contexts/UserContext';
import api from '../service/axiosInstance';
import { langs } from '../i18n/config';
import StepsCounter from '../components/StepsCounter';
import LayoutBase from '../components/LayoutBase';
import Input from '../components/Input';
import Toast from '../components/Toast';

export default function PurchaseInfo() {
  const { t, i18n } = useTranslation('common');
  const { lang } = useParams();
  const [onHover, setOnHover] = useState(false);
  const { userData, setUserData } = useUser();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const [emailConfirmation, setEmailConfirmation] = useState(
    userData?.email || ''
  );
  const [invalidEmailConfirmation, setInvalidEmailConfirmation] =
    useState(false);

  const [terms, setTerms] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [locked, setLocked] = useState(false);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const hasEmail = emailConfirmation.length > 0;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmailConfirmation = emailRegex.test(emailConfirmation);

    const nDisabled = !(
      validEmailConfirmation &&
      name &&
      surname &&
      hasEmail &&
      terms
    );

    setDisabled(nDisabled);
    setLocked(false);
  }, [emailConfirmation, terms, name, surname]);

  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const [cookies, removeCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies['accessToken']) {
      navigate('/');
    }

    const handleBackButtonPress = () => {
      removeCookie('accessToken');
      removeCookie('refreshToken');
      navigate('/login');
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBackButtonPress);
  }, [cookies, navigate]);

  const navigateToPayment = () => {
    const completionUrl = prefix + '/review';
    navigate(completionUrl);
  };

  const createTransaction = async () => {
    setLocked(true);

    const endpoint = '/api/v1/subscriptions/crypto/coinbar/setup';
    const body = { email: emailConfirmation, name: name, surname: surname };

    api
      .post(endpoint, body)
      .then((response) => {
        const { transaction_id, payment_string } = response.data;
        setUserData({
          name: name,
          surname: surname,
          email: emailConfirmation,
          transaction_id: transaction_id,
          payment_string: payment_string,
        });
        navigateToPayment();
      })
      .catch((error) => {
        errorToasts(error);
      })
      .finally(() => {
        setLocked(false);
      });
  };

  const errorToasts = (error) => {
    setLocked(false);
    const detail = error.detail || error.response?.data.detail || error.message;
    let details = [];

    if (_.isArray(detail)) {
      details = _.map(detail, (item) => {
        const loc = _.last(item.loc);
        const msg = item.msg;
        return { msg: t(`${loc}: ${msg}`), type: 'error' };
      });
    } else {
      details = [{ msg: t(detail), type: 'error' }];
    }

    const code = error.response && error.response.status;
    if (code === 422) {
      setToasts((state) =>
        state.concat([{ msg: t('sub_already_done'), type: 'error' }])
      );
    } else {
      setToasts((state) => state.concat(details));
    }

    setDisabled(false);
  };

  return (
    <LayoutBase bgImage={'/images/bg-target.png'}>
      <div className="flex flex-col items-center mb-12 mt-44 sm:mt-10">
        <div className="w-full sm:w-full md:w-3/5 lg:w-2/5 flex flex-row rounded-3xl shadow-2xl bg-accent items-center justify-between pl-12 pr-4 py-8 relative sm:static">
          <div className="flex flex-col justify-center ml-6">
            <h2 className="font-extrabold text-2xl text-black mb-3">
              {t('purchase')}
            </h2>
            <div className="flex flex-row">
              <span className="font-semibold text-lg text-black pr-32">
                {t('product')}
              </span>
              <span className="font-semibold text-lg text-black">
                {t('price')}
              </span>
            </div>
            <div className="flex flex-row">
              <h4
                className="font-light text-sm text-left text-black pr-12"
                style={{ whiteSpace: 'pre-line' }}
              >
                {t('subscriber_access')}
              </h4>
              <h4 className="font-light text-sm text-black">
                {t('sub_value')}
              </h4>
            </div>
          </div>
        </div>
        <div
          className="mb-20 -mt-80 sm:-mt-52 sm:ml-80 sm:-mb-8"
          style={{
            backgroundImage: 'url(/images/chars/char04.png)',
            width: 200,
            height: 240,
            backgroundSize: 'contain',
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="w-full sm:w-full md:w-3/5 lg:w-2/5 flex flex-row rounded-xl items-center justify-center shadow-xl bg-base-200 p-8 mt-6">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-between mb-4">
              <div className="flex flex-col items-center mr-4">
                <span className="text-sm text-black font-light mr-4">
                  {t('name')}
                </span>
                <Input
                  type="text"
                  placeholder={'Junior'}
                  classNames={'w-full'}
                  onChange={(e) => {
                    const input = e.target.value;
                    setName(input);
                  }}
                />
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm text-black font-light">
                  {t('lastname')}
                </span>
                <Input
                  type="text"
                  placeholder={'Silva'}
                  classNames={'w-full'}
                  onChange={(e) => {
                    const input = e.target.value;
                    setSurname(input);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row items-end mb-2">
              <span
                className="font-light text-sm text-center text-black"
                style={{ whiteSpace: 'pre-line' }}
              >
                {t('confirm_email')}
                <span
                  className="tooltip tooltip-right tooltip-primary"
                  data-tip={t('tooltip')}
                >
                  <IoInformationCircle
                    className={`cursor-pointer ${onHover ? 'text-primary' : ''}`}
                    onMouseEnter={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                  />
                </span>
              </span>
            </div>
            <Input
              type="email"
              iconLeftBool={true}
              value={emailConfirmation}
              iconLeft={IoMailOutline}
              iconRightBool={invalidEmailConfirmation}
              iconClassError={invalidEmailConfirmation}
              placeholder={t('email_placeholder')}
              classNames={'w-full'}
              onChange={(e) => {
                const input = e.target.value
                  .toLowerCase()
                  .replace(/[^0-9a-z@._+-]/g, '');
                e.target.value = input;
                setEmailConfirmation(input);
              }}
              onBlur={(e) => {
                const input = e.target.value;

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const validEmail = emailRegex.test(input);

                if (!validEmail) {
                  setInvalidEmailConfirmation(true);
                } else if (validEmail) {
                  setInvalidEmailConfirmation(false);
                }
              }}
            />
            {invalidEmailConfirmation && (
              <div>
                <span className="text-xs font-medium text-red-500">
                  {t('invalid_email')}
                </span>
              </div>
            )}
            <div className="flex flex-row items-center form-control mt-10">
              <input
                type="checkbox"
                className="checkbox checkbox-sm checkbox-primary"
                onChange={(e) => {
                  setTerms(e.target.checked);
                }}
              />
              <span className="label-text ml-3 text-xs">
                {t('by_buying_agree')}
                <Link
                  to={t('terms_of_use_link_xdb')}
                  target="_blank"
                  className="link link-primary"
                >
                  {t('terms_of_use')}
                </Link>
              </span>
            </div>
            <div className="flex flex-col items-center justify-center px-6 mt-8">
              <button
                className="btn btn-primary rounded-full m-2 w-full uppercase text-white text-lg font-extrabold"
                disabled={disabled || locked}
                onClick={createTransaction}
              >
                {locked ? (
                  <span className="loading loading-spinner loading-md"></span>
                ) : (
                  t('finalize')
                )}
              </button>
            </div>
          </div>
        </div>
        <StepsCounter position={2} purchaseFlow={true} />
        <div className="toast toast-top toast-end z-50">
          {_.map(toasts, ({ msg, type }, index) => (
            <Toast
              msg={msg}
              type={type}
              key={msg + index}
              index={index}
              total={toasts.length}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
}
