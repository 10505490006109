import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import {
  IoEyeOutline,
  IoEyeOffOutline,
  IoWarningOutline,
} from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Input = ({
  type,
  iconLeft,
  iconLeftBool,
  iconRight,
  iconRightBool,
  iconClassError,
  classNames,
  ...rest
}) => {
  const [isIconGreen, setIsIconGreen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const eyeIcon = isPasswordVisible ? IoEyeOutline : IoEyeOffOutline;

  const renderIcon = (icon) => {
    const IconComponent = icon;
    return <IconComponent />;
  };

  const handlePhoneChange = (value, country) => {
    if (country && country.format) {
      const validNumberCount = (country.format.match(/\./g) || []).length;
      setIsIconGreen(value.length === validNumberCount);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const passwordInputStyle = clsx(classNames, {
    'w-full pr-4 py-2 text-center border border-solid shadow-lg rounded-full placeholder-gray-400 focus:outline-none': true,
    'pl-12': type != 'text',
    'text-red-500': iconClassError,
    'text-black': !iconClassError,
  });

  return (
    <div className="relative w-full">
      {iconLeftBool && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <IconContext.Provider value={{ size: '1.5em', color: '#ABABAB' }}>
            {renderIcon(iconLeft)}
          </IconContext.Provider>
        </span>
      )}
      {type === 'tel' ? (
        <PhoneInput
          inputProps={{
            autoFocus: true,
            required: true,
            autoFormat: true,
            maxLength: rest.maxLength,
            className: `
                w-full
                py-2
                pr-4
                pl-12
                border-solid
                border
                rounded-full
                text-gray-900
                shadow-lg 
                focus:outline-none
              `,
          }}
          onChange={(value, country, event) => {
            handlePhoneChange(value, country);
            if (rest.onChange) {
              rest.onChange(event);
            }
          }}
          country="us"
          buttonStyle={{
            backgroundColor: '#fff',
            borderRadius: '100%',
            border: 'none',
            borderLeft: 'none',
            position: 'absolute',
            top: '50%',
            left: '0',
            transform: 'translateY(-50%)',
            marginLeft: '0.5rem',
          }}
        />
      ) : (
        <input
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          className={passwordInputStyle}
          {...rest}
        />
      )}
      {iconRightBool && (
        <span
          className="absolute inset-y-0 text-center right-0 flex items-center pr-2"
          onClick={type === 'password' ? handleTogglePasswordVisibility : null}
        >
          <IconContext.Provider
            value={{
              size: '1.5em',
              color: `${isIconGreen ? '#00FF00' : iconClassError ? '#FF0000' : '#ABABAB'}`,
            }}
          >
            {type === 'password' && !iconClassError
              ? renderIcon(eyeIcon)
              : type !== 'password' && !iconClassError
                ? renderIcon(iconRight)
                : renderIcon(IoWarningOutline)}
          </IconContext.Provider>
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'password', 'tel']),
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
};

Input.defaultProps = {
  type: 'text',
  iconLeft: null,
  iconRight: null,
};

export default Input;
