import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { langs } from '../i18n/config';
import api from '../service/axiosInstance';
import { useCookies } from 'react-cookie';
import { IoMailOutline, IoLockClosedOutline } from 'react-icons/io5';

import { useUser } from '../contexts/UserContext';
import Input from '../components/Input';
import StepsCounter from '../components/StepsCounter';
import AmdocsStepsCounter from '../components/AmdocsStepsCounter';
import Card from '../components/Card';
import LayoutBase from '../components/LayoutBase';
import Toast from '../components/Toast';
import { sendGAEvent, formatTimestamp } from '../analyticsEvents';

export default function NewAccount() {
  const { t, i18n } = useTranslation('signup');
  const { lang } = useParams();
  const { userData, setUserData } = useUser();

  const navigate = useNavigate();

  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const isPurchaseFlow = process.env.REACT_APP_FLOW_TYPE === 'purchase';
  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';
  const isAmdocsFlow = process.env.REACT_APP_FLOW_TYPE === 'amdocs';
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [locked, setLocked] = useState(false);

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidInfo, setInvalidInfo] = useState(false);
  const [invalidPassLength, setinvalidPassLength] = useState(false);

  const [, setCookie] = useCookies();
  const [toasts, setToasts] = useState([]);
  const [cookies] = useCookies();
  const codeCookie = cookies['couponCode'];

  useEffect(() => {
    const hasEmail = email.length > 0;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmail = emailRegex.test(email);

    const hasPassword = password.length >= 8;

    const nDisabled = !(validEmail && hasEmail && hasPassword && terms);

    setDisabled(nDisabled);
    setLocked(false);
  }, [email, password, terms]);

  function activateSubscription() {
    const redeemptionCode = codeCookie || 'code_not_found';

    api
      .post('/api/v1/subscriptions/market_one/activate_subscription', {
        redemption_code: redeemptionCode,
      })
      .then(() => {
        const successMessage = [
          { msg: t('subscription_successfully_activated'), type: 'success' },
        ];
        setToasts((prevState) => prevState.concat(successMessage));
        navigate(prefix + '/download');
      })
      .catch((error) => {
        const status = error.response?.status;
        if (status === 404) {
          setToasts((state) =>
            state.concat([
              { msg: 'Invalid Coupon Code Provided', type: 'error' },
            ])
          );
        } else if (status === 400) {
          setToasts((state) =>
            state.concat([
              { msg: 'Expired Coupon Code Provided', type: 'error' },
            ])
          );
        } else {
          const detail = error.response?.data.detail || error.message;
          let details = [];
          if (Array.isArray(detail)) {
            details = detail.map((item) => {
              const loc = item.loc[-1];
              const msg = item.msg;
              return { msg: t(`${loc}: ${msg}`), type: 'error' };
            });
          } else {
            details.push({ msg: t(detail), type: 'error' });
          }
          setToasts((state) => state.concat(details));
        }
      })
      .finally(() => setLocked(false));
  }

  const createUser = async () => {
    setLocked(true);
    const currentTimestamp = Date.now();

    const endpoint = '/api/v1/user';
    const body = {
      email: email,
      password: password,
      marketing_consent: marketing,
    };

    api
      .post(endpoint, body)
      .then((data) => {
        const { access_token, refresh_token } = data.data;

        setCookie('accessToken', access_token);
        setCookie('refreshToken', refresh_token);

        setUserData({
          ...userData,
          email: email,
        });

        sendGAEvent('account_created', {
          timestamp: formatTimestamp(currentTimestamp),
          email: email,
        });

        if (isPurchaseFlow) {
          navigate(prefix + '/purchase');
        } else if (isCheckoutFlow) {
          navigate(prefix + '/checkout');
        } else if (isAmdocsFlow) {
          activateSubscription();
        } else {
          navigate(prefix + '/redeem');
        }
      })
      .catch(() => {
        setInvalidInfo(true);
      })
      .finally(() => {
        setLocked(false);
      });
  };

  const mainCardSubtitle = () => {
    if (isPurchaseFlow || isAmdocsFlow) {
      return '';
    }

    return <div className="sub-title">{t('sign_up_to_redeem')}</div>;
  };

  return (
    <LayoutBase
      bgImage={isShahidFlow ? '' : '/images/bg.png'}
      bgColorClass={isShahidFlow ? 'bg-base-content' : ''}
    >
      <div className="flex flex-col items-center justify-between">
        <Card
          backImage={isShahidFlow ? '' : '/images/chars/bunny.png'}
          title={t('call_to_action_sign_up')}
          subTitle={mainCardSubtitle()}
        >
          <div
            className={`form-control w-full relative mb-3 ${
              isPurchaseFlow || isAmdocsFlow ? 'mt-3' : ''
            }`}
          >
            <Input
              type="email"
              iconLeftBool={true}
              iconLeft={IoMailOutline}
              iconRightBool={invalidEmail || invalidInfo}
              iconClassError={invalidEmail || invalidInfo}
              placeholder={t('email_placeholder')}
              onChange={(e) => {
                const input = e.target.value
                  .toLowerCase()
                  .replace(/[^0-9a-z@._+-]/g, '');
                e.target.value = input;
                setEmail(input);
              }}
              onBlur={(e) => {
                const input = e.target.value;

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const validEmail = emailRegex.test(input);

                if (!validEmail) {
                  setInvalidEmail(true);
                  setInvalidInfo(false);
                } else if (validEmail) {
                  setInvalidEmail(false);
                  setInvalidInfo(false);
                }
              }}
            />
          </div>
          <div className="relative w-full mb-3 form-control">
            <Input
              type="password"
              iconLeftBool={true}
              iconLeft={IoLockClosedOutline}
              iconRightBool={true}
              iconClassError={invalidPassLength}
              placeholder={t('sign_up_password_placeholder')}
              onChange={(e) => {
                setPassword(e.target.value);
                setInvalidInfo(false);
                setinvalidPassLength(false);
              }}
              onBlur={(e) => {
                const password = e.target.value;
                const hasPassword = password.length >= 8;
                if (!hasPassword) {
                  setinvalidPassLength(true);
                }
              }}
            />
          </div>
          {invalidEmail && (
            <div className="text-xs font-medium text-center text-red-500">
              {t('invalid_email')}
            </div>
          )}
          {invalidInfo && (
            <div className="text-xs font-medium text-center text-red-500">
              {t('account_already_created')}
            </div>
          )}
          {invalidPassLength && (
            <div className="text-xs font-medium text-center text-red-500">
              {t('password_length')}
            </div>
          )}
          <div className="mx-8 form-control">
            <label className="cursor-pointer label">
              <input
                type="checkbox"
                className="checkbox checkbox-primary"
                onChange={(e) => {
                  setMarketing(e.target.checked);
                }}
              />
              <span className="text-xs label-text ltr:ml-3 rtl:mr-3">
                {t('i_agree_to_receive_marketing_communications')}
              </span>
            </label>
            <label className="cursor-pointer label">
              <input
                type="checkbox"
                className="checkbox checkbox-primary"
                onChange={(e) => {
                  setTerms(e.target.checked);
                }}
              />
              <span className="text-xs label-text ltr:ml-3 rtl:mr-3">
                {t('agreement_start')}&nbsp;
                <Link
                  to={t('privacy_policy_link')}
                  target="_blank"
                  className={`link ${isShahidFlow ? 'link-accent' : 'link-primary'}`}
                >
                  {t('privacy_policy')}
                </Link>
                &nbsp;{t('agreement_middle_text')}&nbsp;
                <Link
                  to={t('terms_of_use_link')}
                  target="_blank"
                  className={`link ${isShahidFlow ? 'link-accent' : 'link-primary'}`}
                >
                  {t('terms_of_use')}
                </Link>
              </span>
            </label>
          </div>

          <div className="flex flex-col items-center justify-center w-full px-6">
            <button
              className={clsx({
                'btn rounded-full m-2 w-full uppercase text-white text-lg': true,
                'bg-gradient-to-r from-secondary via-tertiary to-primary text-white hover:bg-blend-darken disabled:bg-none':
                  isShahidFlow,
                'btn-primary': !isShahidFlow,
              })}
              disabled={disabled || locked}
              onClick={createUser}
            >
              {locked ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                t('sign_up')
              )}
            </button>
            <Link
              to={prefix + '/'}
              className="w-full m-2 uppercase rounded-full btn btn-primary btn-outline text-md"
            >
              {t('go_back')}
            </Link>
          </div>
        </Card>

        {isAmdocsFlow ? <AmdocsStepsCounter /> : <StepsCounter />}

        <div className="z-50 toast toast-top toast-end">
          {toasts.map(({ msg, type }, index) => (
            <Toast
              msg={msg}
              type={type}
              key={msg + index}
              index={index}
              total={toasts.length}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
}
