import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import api from '../service/axiosInstance';
import Card from '../components/Card';
import Input from '../components/Input';
import LayoutBase from '../components/LayoutBase';
import StepsCounter from '../components/StepsCounter';
import Toast from '../components/Toast';
import { useUser } from '../contexts/UserContext';
import { sendGAEvent, formatTimestamp } from '../analyticsEvents';

export default function Redeem() {
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';
  const { userData } = useUser();

  const { t } = useTranslation('redeem');

  const [textInputDisabled, setTextInputDisabled] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [toasts, setToasts] = useState([]);
  const [locked, setLocked] = useState(false);
  const [redemptionError, setRedemptionError] = useState(false);
  const [errorMsgKey, setErrorMsgKey] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  const currentTimestamp = Date.now();

  useEffect(() => {
    if (!cookies['accessToken']) {
      navigate('/');
    }
  }, [cookies, navigate]);

  useEffect(() => {
    if (isShahidFlow) {
      setDisabled(!termsAccepted);
    } else {
      setDisabled(false);
    }
  }, [termsAccepted]);

  const checkCoupon = () => {
    const code = cookies['couponCode'] || couponCode;
    api
      .post('/api/v1/coupons/check', {
        code,
      })
      .then((response) => {
        if (response.data?.response_code === 1001) {
          getCouponDetails();
        } else {
          errorToasts(response.data);
        }
      })
      .catch((error) => {
        errorToasts(error);
      });
  };

  const getCouponDetails = () => {
    setLocked(true);
    api
      .get('/api/v1/coupons?coupon_code=' + encodeURIComponent(couponCode))
      .then(({ data }) => {
        if (data.detail) {
          errorToasts(data);
        } else {
          setTextInputDisabled(true);
          setToasts((state) =>
            state.concat([{ msg: t('valid_coupon'), type: 'success' }])
          );

          setCookie('couponCode', couponCode);

          setCookie('billingFrequency', data.billing_frequency);
          setCookie('billingPeriodInDays', data.billing_period_in_days);
          setCookie('billingProvider', data.billing_provider);
          setCookie('currencyAmount', data.currency_amount);
          setCookie('currencyName', data.currency_name);
          setCookie('durationInDays', data.duration_in_days);
          setCookie('stripePriceId', data.stripe_price_id);

          const billingProvider = data.billing_provider;

          switch (billingProvider) {
            case 'internal':
              redeemCode();
              break;
            case 'minu':
              redeemCode('minu');
              break;
            default:
              navigateToCheckout();
              sendGAEvent('redemption_success', {
                timestamp: formatTimestamp(currentTimestamp),
                email: userData?.email,
                code_used: couponCode,
              });
              break;
          }
        }
      })
      .catch(errorToasts)
      .finally(() => setLocked(false));
  };

  const redeemCode = (billingProvider) => {
    const code = cookies['couponCode'] || couponCode;
    const baseUrl = '/api/v1/coupons/redeem';

    const providerBasedUrl =
      billingProvider === 'minu' ? `${baseUrl}/${billingProvider}` : baseUrl;
    api
      .post(providerBasedUrl, {
        code,
      })
      .then((response) => {
        if (response.data?.response_code === 1000) {
          navigateToDownload();
          sendGAEvent('redemption_success', {
            timestamp: formatTimestamp(currentTimestamp),
            email: userData?.email,
            code_used: code,
          });
        } else {
          sendGAEvent('redemption_error', {
            timestamp: formatTimestamp(currentTimestamp),
            email: userData?.email,
            code_used: code,
            error_message: response.data.detail || 'Unknown error',
          });
          errorToasts(response.data);
        }
      })
      .catch((error) => {
        sendGAEvent('redemption_error', {
          timestamp: formatTimestamp(currentTimestamp),
          email: userData?.email,
          code_used: code,
          error_message: error.message || 'Unknown error',
        });
        errorToasts(error);
      });
  };

  const navigateToDownload = () => {
    const completionUrl = '/download';
    navigate(completionUrl);
  };
  const navigateToCheckout = () => {
    const completionUrl = '/checkout';
    navigate(completionUrl);
  };

  const errorToasts = (error) => {
    setLocked(false);
    const couponUsed = cookies['couponCode'] || couponCode;
    const detail = error.detail || error.response?.data.detail || error.message;
    let details = [];

    if (_.isArray(detail)) {
      details = _.map(detail, (item) => {
        const loc = _.last(item.loc);
        const msg = item.msg;
        return { msg: t(`${loc}: ${msg}`), type: 'error' };
      });
    } else {
      details = [{ msg: t(detail), type: 'error' }];
    }

    const code = error.response?.data?.response_code;

    switch (code) {
      case 4041:
        setRedemptionError(true);
        setErrorMsgKey('coupon_invalid');
        break;
      case 4032:
        setRedemptionError(true);
        setErrorMsgKey('coupon_expired');
        break;
      case 4033:
        setRedemptionError(true);
        setErrorMsgKey('invalid_territory');
        break;
      case 4035:
        setRedemptionError(true);
        setErrorMsgKey('coupon_already_used');
        break;
      case 4037:
        setRedemptionError(true);
        setErrorMsgKey('user_already_subscribed');
        break;
      default:
        setToasts((state) => state.concat(details));
    }

    sendGAEvent('redemption_error', {
      timestamp: formatTimestamp(currentTimestamp),
      email: userData?.email,
      code_used: couponUsed,
      error_message: errorMsgKey || 'Unknown error',
    });

    setTextInputDisabled(false);
  };

  const couponCodeCookie = cookies['couponCode'];
  useEffect(() => {
    if (couponCodeCookie) {
      setLocked(false);
      setCouponCode(decodeURI(couponCodeCookie));
    }
  }, [couponCodeCookie]);

  return (
    <LayoutBase
      bgImage={isShahidFlow ? '' : '/images/bg-blue.png'}
      bgColorClass={isShahidFlow ? 'bg-base-content' : ''}
    >
      <div className="flex flex-col items-center justify-between mb-32">
        <Card
          frontImage={isShahidFlow ? '' : '/images/chars/dog.png'}
          title={t('lift_with_us')}
          titleClassNames={`w-full text-2xl font-extrabold ${isShahidFlow ? '' : 'text-primary'}`}
          subTitle={t('description_redeem')}
        >
          <Input
            type="text"
            placeholder={t('coupon_placeholder')}
            iconRightBool={redemptionError}
            iconClassError={redemptionError}
            maxLength={50}
            onChange={(e) => {
              setCouponCode(e.target.value);
              setRedemptionError(false);
            }}
            value={couponCodeCookie ? couponCodeCookie : null}
            disabled={textInputDisabled || couponCodeCookie}
          />
          {redemptionError && (
            <div className="mt-3 text-sm font-medium text-center text-red-500">
              {redemptionError && t(errorMsgKey)}
            </div>
          )}
          {isShahidFlow && (
            <div className="mx-8 mt-3">
              <div className="flex px-1 py-2 text-xs text-sky-700">
                <IoInformationCircleOutline className="size-6" />
                <p className="ml-2">{t('consent_terms_info')}</p>
              </div>
              <div className="form-control">
                <label className="cursor-pointer label">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-accent"
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                  <span className="ml-3 text-xs label-text">
                    {t('by_redeeming_your_voucher')}&nbsp;
                    <Link
                      to={t('privacy_policy_link')}
                      target="_blank"
                      className="link link-accent"
                    >
                      {t('privacy_policy')}
                    </Link>
                    &nbsp;{t('consent_middle_text')}&nbsp;
                    <Link
                      to={t('terms_and_conditions_link')}
                      target="_blank"
                      className="link link-accent"
                    >
                      {t('terms_and_conditions')}
                    </Link>
                  </span>
                </label>
              </div>
            </div>
          )}
          <div
            className={clsx({
              'flex items-center w-full justify-between': true,
              'flex-col-reverse px-6': isShahidFlow,
              'flex-row px-2 sm:px-4 pt-16 pb-4': !isShahidFlow,
            })}
          >
            <Link
              to={'/'}
              className={clsx({
                'btn uppercase btn-outline rounded-full text-lg font-bold': true,
                'btn-primary w-full m-2': isShahidFlow,
                'btn-accent py-1 px-8 sm:px-9': !isShahidFlow,
              })}
            >
              {t('go_back')}
            </Link>
            <button
              className={clsx({
                'btn uppercase rounded-full text-lg font-bold text-white': true,
                'bg-gradient-to-r from-secondary via-tertiary to-primary text-white hover:bg-blend-darken disabled:bg-none w-full m-2':
                  isShahidFlow,
                'btn-primary py-1 px-6 sm:px-9': !isShahidFlow,
              })}
              onClick={checkCoupon}
              disabled={disabled || locked}
            >
              {locked ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : isShahidFlow ? (
                t('validate')
              ) : (
                t('check_coupon')
              )}
            </button>
          </div>
        </Card>

        <StepsCounter position={2} />

        <div className="z-50 toast toast-top toast-end">
          {_.map(toasts, ({ msg, type }, index) => (
            <Toast
              msg={msg}
              type={type}
              key={msg + index}
              index={index}
              total={toasts.length}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
}
