import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';

import router from './routes/router';
import './i18n/config';
import { UserProvider } from './contexts/UserContext';

function App() {
  const flow = process.env.REACT_APP_FLOW_TYPE;
  const getTrackingId = () => {
    switch (flow) {
      case 'redeem':
        return process.env.REACT_APP_REDEEM_TRACKING_ID;
      case 'shahid':
        return process.env.REACT_APP_SHAHID_TRACKING_ID;
      case 'amdocs':
        return process.env.REACT_APP_AMDOCS_TRACKING_ID;
      default:
        console.warn('No flow config. Use fallback ID.');
        return process.env.REACT_APP_REDEEM_TRACKING_ID;
    }
  };

  const TRACKING_ID = getTrackingId();

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({
        page: document.location.pathname + document.location.search,
      });
    } else {
      console.error('GA Tracking ID not configured.');
    }
  }, [TRACKING_ID]);

  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;
