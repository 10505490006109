import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export default function StepsCounter({ position = 1 }) {
  const [, removeCookie] = useCookies();

  const isPurchaseFlow = process.env.REACT_APP_FLOW_TYPE === 'purchase';
  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';

  const max = isCheckoutFlow ? 3 : 4;

  const disabledCircle = '#B5B5B5';
  const disabledText = '#FFFFFF';

  let oddCircle;
  let oddText;
  let evenCircle;
  let evenText;
  if (isShahidFlow) {
    oddCircle = evenCircle = '#00D358';
    oddText = evenText = '#191D24';
  } else {
    oddCircle = '#FF6714';
    oddText = '#FFFFFF';

    evenCircle = '#FFDE5B';
    evenText = '#A217AB';
  }

  const fillCircle1 = position === 1 ? oddCircle : disabledCircle;
  const fillCircle2 = position === 2 ? evenCircle : disabledCircle;
  const fillCircle3 = position === 3 ? oddCircle : disabledCircle;
  const fillCircle4 = position === 4 ? evenCircle : disabledCircle;

  const fillPath1 = position === 1 ? oddText : disabledText;
  const fillPath2 = position === 2 ? evenText : disabledText;
  const fillPath3 = position === 3 ? oddText : disabledText;
  const fillPath4 = position === 4 ? evenText : disabledText;

  const handleNavigate1 = () =>
    isPurchaseFlow ? handleNavigatePurchase(1) : handleNavigate(1);
  const handleNavigate2 = () =>
    isPurchaseFlow ? handleNavigatePurchase(2) : handleNavigate(2);
  const handleNavigate3 = () =>
    isPurchaseFlow ? handleNavigatePurchase(3) : handleNavigate(3);
  const handleNavigate4 = () =>
    isPurchaseFlow ? handleNavigatePurchase(4) : handleNavigate(4);

  const navigate = useNavigate();

  const handleNavigate = (p) => {
    if (p < position) {
      switch (p) {
        case 1:
          navigate('/');
          break;
        case 2:
          if (isCheckoutFlow) {
            navigate('/checkout');
          } else {
            navigate('/redeem');
          }
          break;
        case 3:
          if (isCheckoutFlow) {
            navigate('/download');
          } else {
            navigate('/checkout');
          }
          break;
        case 4:
          navigate('/download');
          break;
        default:
          break;
      }
    }
  };

  const handleNavigatePurchase = (p) => {
    if (p < position && position !== 4) {
      switch (p) {
        case 1:
          navigate('/');
          removeCookie('accessToken');
          removeCookie('refreshToken');
          break;
        case 2:
          removeCookie('accessToken');
          removeCookie('refreshToken');
          navigate('/login');
          break;
        case 3:
          removeCookie('accessToken');
          removeCookie('refreshToken');
          navigate('/login');
          break;
        case 4:
          navigate('/download');
          break;
        default:
          break;
      }
    }
  };

  const handleOver1 = (e) => handleOver(e, 1);
  const handleOver2 = (e) => handleOver(e, 2);
  const handleOver3 = (e) => handleOver(e, 3);
  const handleOver4 = (e) => handleOver(e, 4);

  const handleLeave1 = () => handleLeave(1);
  const handleLeave2 = () => handleLeave(2);
  const handleLeave3 = () => handleLeave(3);
  const handleLeave4 = () => handleLeave(4);

  const handleOver = (e) => {
    var follow = document.getElementById('follow');
    follow.style.left = e.pageX + 'px';
    follow.style.top = e.pageY + 'px';
  };
  const handleLeave = () => {
    var follow = document.getElementById('follow');
    follow.style.display = 'none';
  };

  const viewBoxWidth = 70.5 * max;
  const imgWidth = 49.35 * max;

  return (
    <div className="m-2 mt-6">
      <svg
        width={imgWidth}
        height={37.8}
        viewBox={'0 0 ' + viewBoxWidth + ' 54'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {max >= 1 && (
          <g>
            <circle
              cx="26.816"
              cy="26.816"
              r="26.816"
              fill={fillCircle1}
              className="cursor-pointer"
              onClick={handleNavigate1}
              onMouseOver={handleOver1}
              onMouseLeave={handleLeave1}
            />
            <path
              className="pointer-events-none"
              d="M25.1974 37.0752V19.6512L23.2534 20.1552C22.9654 20.2272 22.5694 20.2992 22.2814 20.2992C20.9854 20.2992 19.8694 19.2192 19.8694 17.9232C19.8694 16.8072 20.5894 15.8712 21.7054 15.5472L25.2694 14.5032C26.3134 14.2152 27.1054 14.0352 27.8614 14.0352H27.9334C29.4454 14.0352 30.6694 15.2592 30.6694 16.7712V37.0752C30.6694 38.5872 29.4454 39.8112 27.9334 39.8112C26.4214 39.8112 25.1974 38.5872 25.1974 37.0752Z"
              fill={fillPath1}
            />
          </g>
        )}
        {max >= 2 && (
          <g>
            <circle
              cx="104.669"
              cy="26.816"
              r="26.816"
              fill={fillCircle2}
              className="cursor-pointer"
              onClick={handleNavigate2}
              onMouseOver={handleOver2}
              onMouseLeave={handleLeave2}
            />
            <path
              className="pointer-events-none"
              d="M94.5698 36.9672C94.5698 35.8872 95.0738 34.9512 96.0098 34.1952L103.174 28.3272C106.306 25.7712 107.53 24.3672 107.53 22.2792C107.53 20.1552 106.126 19.0032 104.146 19.0032C102.562 19.0032 101.446 19.6872 100.042 21.1992C99.6818 21.5952 99.0338 21.9912 98.1698 21.9912C96.7658 21.9912 95.6498 20.8752 95.6498 19.4712C95.6498 18.8592 95.9018 18.2112 96.2978 17.7432C98.3138 15.4032 100.69 14.0352 104.506 14.0352C109.726 14.0352 113.182 17.0952 113.182 21.8112V21.8832C113.182 26.0952 111.022 28.1832 106.558 31.6392L102.454 34.8072H111.238C112.57 34.8072 113.65 35.8872 113.65 37.2192C113.65 38.5512 112.57 39.5952 111.238 39.5952H97.5578C95.8298 39.5952 94.5698 38.6232 94.5698 36.9672Z"
              fill={fillPath2}
            />
          </g>
        )}
        {max >= 3 && (
          <g>
            <circle
              cx="182.521"
              cy="26.816"
              r="26.816"
              fill={fillCircle3}
              className="cursor-pointer"
              onClick={handleNavigate3}
              onMouseOver={handleOver3}
              onMouseLeave={handleLeave3}
            />
            <path
              className="pointer-events-none"
              d="M182.105 40.0272C178.505 40.0272 175.805 38.9112 173.753 37.1472C173.249 36.7152 172.817 35.9592 172.817 35.1672C172.817 33.7272 174.041 32.5032 175.481 32.5032C176.273 32.5032 176.813 32.8272 177.245 33.1872C178.685 34.4112 180.197 35.0592 182.177 35.0592C184.265 35.0592 185.741 33.8712 185.741 31.9992V31.9272C185.741 29.8752 183.905 28.7232 180.809 28.7232H179.945C178.649 28.7232 177.569 27.6432 177.569 26.3472C177.569 25.5912 177.893 24.9432 178.757 24.0792L183.653 19.1472H175.913C174.617 19.1472 173.537 18.0672 173.537 16.7712C173.537 15.4752 174.617 14.3952 175.913 14.3952H188.189C189.809 14.3952 190.997 15.3312 190.997 16.8432C190.997 18.2112 190.349 18.9672 189.341 19.9392L184.409 24.6552C187.829 25.2312 191.177 27.0312 191.177 31.7112V31.7832C191.177 36.5352 187.721 40.0272 182.105 40.0272Z"
              fill={fillPath3}
            />
          </g>
        )}
        {max >= 4 && (
          <g>
            <circle
              cx="255.183"
              cy="26.816"
              r="26.816"
              fill={fillCircle4}
              className="cursor-pointer"
              onClick={handleNavigate4}
              onMouseOver={handleOver4}
              onMouseLeave={handleLeave4}
            />
            <path
              className="pointer-events-none"
              d="M256.67 37.1832V34.1592H246.302C244.826 34.1592 243.602 33.0792 243.602 31.6032C243.602 30.4512 244.07 29.5512 244.898 28.5792L255.842 15.8712C256.886 14.6472 257.678 13.9992 259.298 13.9992C260.774 13.9992 261.962 15.1512 261.962 16.6272V29.6592H263.294C264.554 29.6592 265.562 30.6672 265.562 31.9272C265.562 33.1872 264.554 34.1592 263.294 34.1592H261.962V37.1832C261.962 38.6592 260.774 39.8112 259.298 39.8112C257.822 39.8112 256.67 38.6592 256.67 37.1832ZM249.974 29.6592H256.67V21.8112L249.974 29.6592Z"
              fill={fillPath4}
            />
          </g>
        )}
      </svg>

      <div
        id="follow"
        style={{
          position: 'absolute',
          backgroundColor: '#000',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '5px',
          display: 'none',
        }}
      >
        Segue o mouse
      </div>
    </div>
  );
}
