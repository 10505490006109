import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import api from '../service/axiosInstance';
import _ from 'lodash';

import { useCookies } from 'react-cookie';
import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import Toast from '../components/Toast';
import CheckoutForm from '../components/CheckoutForm';
import PlanCheckoutForm from '../components/PlanCheckoutForm';
import StepsCounter from '../components/StepsCounter';

import LayoutBase from '../components/LayoutBase';

let stripeKey =
  process.env.REACT_APP_ENV == 'production'
    ? process.env.REACT_APP_STRIPE_PRODUCTION_KEY
    : process.env.REACT_APP_STRIPE_STAGING_KEY;

const stripePromise = loadStripe(stripeKey);

export default function Checkout() {
  const { t, i18n } = useTranslation('common');
  const [clientSecret, setClientSecret] = useState(null);

  const stripeOptions = {
    clientSecret: clientSecret,
    locale: i18n.language,
  };

  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';

  const [toasts, setToasts] = useState([]);
  const errorToasts = (error) => {
    const data = error.response?.data || error;

    if (data.response_code) {
      const responseCode = data.response_code;
      let responseCodes = [];

      if (_.isArray(responseCode)) {
        responseCodes = _.map(responseCode, (item) => {
          return t(item);
        });
      } else {
        responseCodes = [t(responseCode)];
      }

      setToasts((state) => state.concat(responseCodes));
    } else if (data.detail) {
      const detail = data.detail;
      let details = [];

      if (_.isArray(detail)) {
        details = _.map(detail, (item) => {
          const loc = _.last(item.loc);
          const msg = item.msg;
          return t(`${loc}: ${msg}`);
        });
      } else {
        details = [t(detail)];
      }

      setToasts((state) => state.concat(details));
    } else if (data.message) {
      setToasts((state) => state.concat([t(data.message)]));
    }
  };

  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies['accessToken']) {
      navigate('/');
    }

    const stripeSetup = async () => {
      api
        .post('/api/v1/subscriptions/stripe/setup')
        .then(({ data }) => {
          setClientSecret(data.setup_intent_secret);
          setCookie('setupIntentSecret', data.setup_intent_secret);
        })
        .catch(errorToasts);
    };

    const configStripe = async () => {
      if (!cookies['accessToken']) {
        navigate('/');
      } else {
        await stripeSetup();
      }
    };
    configStripe();
  }, []);

  return (
    <LayoutBase
      bgImage={`${!isCheckoutFlow ? '/images/bg-checkout.png' : ''}`}
      bgColorClass={`${isCheckoutFlow ? 'bg-light-pink' : ''}`}
    >
      <div
        className={`flex flex-col justify-center w-full ${isCheckoutFlow ? 'bg-light-pink mb-16' : 'items-center'}`}
      >
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={stripeOptions}>
            {isCheckoutFlow ? <PlanCheckoutForm /> : <CheckoutForm />}
          </Elements>
        )}
        {!isCheckoutFlow && <StepsCounter position={isCheckoutFlow ? 2 : 3} />}
      </div>

      <div className="z-50 toast toast-top toast-end">
        {_.map(toasts, (msg, index) => (
          <Toast
            msg={msg}
            key={msg + index}
            index={index}
            total={toasts.length}
          />
        ))}
      </div>
    </LayoutBase>
  );
}
