import React from 'react';
import { createHashRouter } from 'react-router-dom';

import HomePage from '../pages/HomePage/HomePage';
import NewAccount from '../pages/NewAccount';
import Login from '../pages/Login';
import Redeem from '../pages/Redeem';
import Checkout from '../pages/Checkout';
import Download from '../pages/Download';
import Forgot from '../pages/Forgot';
import PurchaseInfo from '../pages/PurchaseInfo';
import ReviewPaymentInfo from '../pages/ReviewPaymentInfo';
import AmdocsActivateSubscription from '../pages/AmdocsActivateSubscription';

const router = createHashRouter([
  {
    path: '/:lang?/',
    element: <HomePage />,
  },
  {
    path: '/:lang?/new-account',
    element: <NewAccount />,
  },
  {
    path: '/:lang?/login',
    element: <Login />,
  },
  {
    path: '/:lang?/redeem',
    element: <Redeem />,
  },
  {
    path: '/:lang?/checkout',
    element: <Checkout />,
  },
  {
    path: '/:lang?/download',
    element: <Download />,
  },
  {
    path: '/:lang?/forgot',
    element: <Forgot />,
  },
  {
    path: '/:lang?/purchase',
    element: <PurchaseInfo />,
  },
  {
    path: '/:lang?/review',
    element: <ReviewPaymentInfo />,
  },
  {
    path: '/:lang?/activate',
    element: <AmdocsActivateSubscription />,
  },
]);

export default router;
