import { React, useEffect, useState } from 'react';
import clsx from 'clsx';

import { langs } from './../i18n/config';

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';

import { IoChevronDown, IoCheckmarkCircle } from 'react-icons/io5';

import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation('common');
  const [selectedLanguage, setSelectedLanguage] = useState(
    langs.find((language) => language.code.toLowerCase() == i18n.language)
  );

  useEffect(() => {
    changeRTLMode();
    i18n.changeLanguage(selectedLanguage.code.toLowerCase());
  }, [selectedLanguage]);

  function changeRTLMode() {
    var html = document.querySelector('html');
    if (selectedLanguage.code == 'AR') {
      html.setAttribute('dir', 'rtl');
    } else {
      html.setAttribute('dir', 'ltr');
    }
  }

  return (
    <>
      <Listbox value={selectedLanguage} onChange={setSelectedLanguage}>
        <ListboxButton
          className={clsx(
            'rounded-lg bg-white text-left font-semibold text-black',
            'md:inline-block'
          )}
        >
          {t('language')}{' '}
          <span className="text-slate-400 ">{selectedLanguage.code}</span>
          <IoChevronDown
            className="inline ml-1 mr-2"
            value={{ size: '1.5em', color: '#ABABAB' }}
          />
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          className="rtl:text-left bg-white shadow border border-solid border-gray-100 gap-2 rounded-lg py-2 px-2 data-[selected]:bg-custom-lime"
        >
          {langs.map((language) => (
            <ListboxOption
              key={language.code}
              value={language}
              className="py-2  px-2 font-semibold rounded-lg text-black text-sm data-[focus]:bg-custom-gray data-[selected]:bg-custom-lime"
            >
              <span className="text-slate-400">{language.code}</span>{' '}
              {language.name}
              {language.code.toLowerCase() == i18n.language && (
                <IoCheckmarkCircle
                  className="inline ml-1 mr-2"
                  fill="#004C2B"
                />
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </>
  );
};

export default LanguageSelector;
