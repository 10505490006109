import React from 'react';
import Card from '../../components/Card';
import { renderDefaultActions } from './Utils/renderActions';
import { useTranslation } from 'react-i18next';

export default function DefaultFlow({getButtonClass }) {
  const { t } = useTranslation('homepage');

  return (
    <Card
      title={t('we_re_waiting_for_you')}
      backImage={'/images/chars/bunny.png'}
      titleClassNames="w-4/3 text-4xl font-bold py-3 px-6"
      subTitle={<span className="sub-title">{t('login_to_activate')}</span>}
    >
      {renderDefaultActions(getButtonClass)}
    </Card>
  );
}
