import React, { useEffect, useState } from 'react';

import { IoMdClose } from 'react-icons/io';

export default function Toast({ msg, index, total, type = 'error' }) {
  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  if (isOpen && index >= total - 3) {
    return (
      <div
        className={`alert flex items-center justify-between text-white`}
        style={{
          backgroundColor: type === 'success' ? '#B5F8C3' : '#EF5D6D',
          color: type === 'success' ? '#333' : '#FFF',
        }}
      >
        <span
          style={{
            maxWidth: '390px',
            textWrap: 'wrap',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {msg}
        </span>
        <button
          className="btn btn-ghost rounded-full p-3"
          onClick={() => setOpen(false)}
        >
          <IoMdClose className="h-5 w-5" />
        </button>
      </div>
    );
  } else {
    return <></>;
  }
}
