import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../service/axiosInstance';
import Card from '../components/Card';
import Input from '../components/Input';
import LayoutBase from '../components/LayoutBase';
import StepsCounter from '../components/StepsCounter';
import Toast from '../components/Toast';
import { langs } from '../i18n/config';
import { useUser } from '../contexts/UserContext';
import { sendGAEvent, formatTimestamp } from '../analyticsEvents';

export default function AmdocsActivateSubscription() {
  const { t, i18n } = useTranslation('common');
  const { lang } = useParams();
  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const { userData } = useUser();

  const [locked, setLocked] = useState(false);
  const [code, setCode] = useState('');
  const [toasts, setToasts] = useState([]);
  const [invalidCode, setInvalidCode] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const codeCookie = cookies['couponCode'];

  function activateSubscription() {
    setLocked(true);
    const redeemptionCode = codeCookie || code;
    const currentTimestamp = Date.now();

    api
      .post('/api/v1/subscriptions/market_one/activate_subscription', {
        redemption_code: redeemptionCode,
      })
      .then(() => {
        const successMessage = [
          { msg: t('subscription_successfully_activated'), type: 'success' },
        ];
        sendGAEvent('activation_success', {
          timestamp: formatTimestamp(currentTimestamp),
          email: userData?.email,
          code_used: redeemptionCode,
        });
        setToasts((prevState) => prevState.concat(successMessage));
        navigate(prefix + '/download');
      })
      .catch((error) => {
        const status = error.response?.status;
        if (status === 404) {
          setInvalidCode(true);
        } else if (status === 400) {
          setExpiredCode(true);
        } else {
          const detail = error.response?.data.detail || error.message;
          let details = [];
          if (Array.isArray(detail)) {
            details = detail.map((item) => {
              const loc = item.loc[-1];
              const msg = item.msg;
              return { msg: t(`${loc}: ${msg}`), type: 'error' };
            });
          } else {
            details.push({ msg: t(detail), type: 'error' });
          }
          sendGAEvent('activation_error', {
            timestamp: formatTimestamp(currentTimestamp),
            email: userData?.email,
            code_used: redeemptionCode,
            error_message: detail || 'Unknown error',
          });
          setToasts((state) => state.concat(details));
        }
      })
      .finally(() => setLocked(false));
  }

  return (
    <LayoutBase bgImage={'/images/bg-blue.png'} classImage={true}>
      <div className="flex flex-col items-center justify-between mb-32">
        <Card
          frontImage={'/images/chars/dog.png'}
          title={t('lift_with_us')}
          titleClassNames="w-full text-2xl font-extrabold text-primary"
          subTitle={t('validate_code')}
        >
          <Input
            type="text"
            placeholder={t('coupon_placeholder')}
            iconRightBool={invalidCode}
            iconClassError={invalidCode}
            maxLength={50}
            onChange={(e) => {
              setCode(e.target.value);
              setInvalidCode(false);
              setExpiredCode(false);
            }}
            value={codeCookie ? codeCookie : code}
            disabled={!!codeCookie}
          />
          {invalidCode && (
            <div className="mt-3">
              <p className="text-sm font-medium text-center text-red-500">
                {t('invalid_code')}
              </p>
            </div>
          )}
          {expiredCode && (
            <div className="mt-3">
              <p className="text-sm font-medium text-center text-red-500">
                {t('expired_code')}
              </p>
            </div>
          )}
          <div className="flex flex-row items-center justify-between w-full px-2 pt-16 pb-4 sm:px-6">
            <Link
              to={prefix + '/'}
              className="px-10 py-1 text-lg font-bold uppercase rounded-full btn btn-accent btn-outline"
            >
              {t('go_back')}
            </Link>
            <button
              className="px-10 py-1 text-lg font-bold text-white uppercase rounded-full btn btn-primary"
              onClick={activateSubscription}
              disabled={locked}
            >
              {locked ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                t('send')
              )}
            </button>
          </div>
        </Card>

        <StepsCounter position={2} />

        <div className="z-50 toast toast-top toast-end">
          {toasts.map(({ msg, type }, index) => (
            <Toast
              msg={msg}
              type={type}
              key={msg + index}
              index={index}
              total={toasts.length}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
}
