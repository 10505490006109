import React from 'react';
import Header from './Header';
import Footer from './Footer';

const LayoutBase = ({ children, bgImage, bgColorClass /*, classImage*/ }) => {
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';
  const isCheckoutFlow = process.env.REACT_APP_FLOW_TYPE === 'checkout';

  return (
    <div
      className={`min-h-screen ${isCheckoutFlow ? 'bg-light-pink' : 'bg-base-light'}`}
      data-theme={isShahidFlow ? 'pk_shahid_light' : 'pk_light'}
    >
      <div className="h-15">
        <Header />
      </div>
      <div
        className={`flex-col items-center justify-between min-h-[80vh] p-2 pb-16 h-auto ${bgColorClass}`}
        style={{
          backgroundImage: bgImage ? `url(${bgImage})` : 'none',
          // backgroundSize: classImage ? "100% 160%" : "100% 140%",
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {children}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutBase;
