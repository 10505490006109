import React from 'react';
import Card from '../components/Card';

export default function SpinnerLoading() {
  return (
    <Card>
      <img
        src="/images/spinner-icon.svg"
        width={30}
        height={60}
        alt="Loading"
        className="m-8"
      />
    </Card>
  );
}
