import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('footer');

  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: 20,
          marginTop: -25,
          backgroundImage: `url(${isShahidFlow ? '/images/footer-shahid.png' : '/images/footer.png'})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          zIndex: 100,
        }}
      ></div>
      <footer className="w-full p-6 pb-10 footer sm:p-10 text-base-content bg-base-light">
        <div className="w-full">
          <div className="w-full mb-2 prose text-center md:text-left">
            <h3>{t('questions')}</h3>
          </div>
          <nav className="flex flex-wrap justify-center w-full leading-loose md:justify-normal">
            <a
              href={t('help_center_link')}
              className="inline-block mx-2 link link-hover link-black md:mx-0 md:mr-2"
              target="_blank"
              rel="noreferrer"
            >
              \{t('common_questions')}
            </a>
            <a
              href={t('terms_of_use_link')}
              className="inline-block mx-2 link link-hover link-black"
              target="_blank"
              rel="noreferrer"
            >
              \{t('terms_of_use')}
            </a>
            <a
              href={t('privacy_policy_link')}
              className="inline-block mx-2 link link-hover link-black"
              target="_blank"
              rel="noreferrer"
            >
              \{t('privacy_policy')}
            </a>
            <a
              href={t('cookie_policy_link')}
              className="inline-block mx-2 link link-hover link-black"
              target="_blank"
              rel="noreferrer"
            >
              \{t('cookie_policy')}
            </a>
          </nav>
        </div>
        <aside className="self-center hidden md:block justify-self-end">
          <a
            href={
              'https://www.kidsafeseal.com/certifiedproducts/movile_playkids_app.html'
            }
            target="_blank"
            rel="noreferrer"
            className="p-2"
          >
            <img
              src="/images/kidssafe.png"
              alt="Kids Safe"
              width={128}
              height={44}
            />
          </a>
        </aside>
      </footer>
    </div>
  );
};

export default Footer;
