import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import api from '../service/axiosInstance';
import Toast from '../components/Toast';
import StepsCounter from '../components/StepsCounter';
import AmdocsStepsCounter from '../components/AmdocsStepsCounter';
import ImageCard from '../components/Card';
import LayoutBase from '../components/LayoutBase';
import { IoLockClosedOutline, IoMailOutline } from 'react-icons/io5';
import Input from '../components/Input';

export default function Forgot() {
  const isShahidFlow = process.env.REACT_APP_FLOW_TYPE === 'shahid';
  const isAmdocsFlow = process.env.REACT_APP_FLOW_TYPE === 'amdocs';

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [locked, setLocked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [toasts, setToasts] = useState([]);

  const [alertStatus, setAlertStatus] = useState(null);

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidInfo, setInvalidInfo] = useState(false);

  useEffect(() => {
    const hasEmail = email.length > 0;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmail = emailRegex.test(email);

    const nDisabled = !(validEmail && hasEmail);

    setDisabled(nDisabled);
    setLocked(false);
  }, [email]);

  const resetPassword = () => {
    api
      .post('/api/v1/user/reset_password', { email })
      .then(() => {
        setEmailSent(true);

        setAlertStatus('success');
      })
      .catch(errorToasts);
  };

  const resetPasswordConfirm = () => {
    api
      .post('/api/v1/user/reset_password/confirm', {
        email,
        password,
        reset_code: resetCode,
      })
      .then(({ data: { message } }) => {
        setEmailSent(true);
        setToasts((state) => state.concat([{ msg: message, type: 'success' }]));
        setAlertStatus('success');
        navigate('/login');
      })
      .catch(errorToasts);
  };

  const errorToasts = (error) => {
    setLocked(false);
    const detail = error.detail || error.response?.data.detail || error.message;
    let details = [];

    if (_.isArray(detail)) {
      details = _.map(detail, (item) => {
        const loc = _.last(item.loc);
        const msg = item.msg;
        return { msg: t(`${loc}: ${msg}`), type: 'error' };
      });
    } else {
      details = [{ msg: t(detail), type: 'error' }];
    }

    const hasEmailError = _.find(details, ({ msg }) => {
      return /.*email.*/.test(msg);
    });
    if (hasEmailError) {
      setAlertStatus('error');
    } else {
      setToasts((state) => state.concat(details));
    }
  };

  const alertIsError = alertStatus === 'error';
  return (
    <LayoutBase
      bgImage={isShahidFlow ? '' : '/images/bg-password.png'}
      bgColorClass={isShahidFlow ? 'bg-base-content' : ''}
    >
      <div className="flex flex-col items-center justify-between mb-24">
        <ImageCard
          backImage={isShahidFlow ? '' : '/images/chars/cat.png'}
          title={t('forgot_your_password_title')}
          subTitle={t('forgot_your_password_subtitle')}
        >
          <Input
            type="email"
            disabled={emailSent}
            iconLeftBool={true}
            iconLeft={IoMailOutline}
            iconRightBool={invalidEmail || invalidInfo}
            placeholder={t('email_placeholder')}
            iconClassError={invalidEmail || invalidInfo || alertIsError}
            onChange={(e) => {
              setInvalidEmail(false);
              setInvalidInfo(false);
              const input = e.target.value
                .toLowerCase()
                .replace(/[^0-9a-z@._+-]/g, '');
              e.target.value = input;
              setEmail(input);
              setAlertStatus(null);
            }}
            onBlur={(e) => {
              const input = e.target.value;

              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              const validEmail = emailRegex.test(input);

              if (!validEmail) {
                setInvalidEmail(true);
              } else if (validEmail) {
                setInvalidEmail(false);
                setInvalidInfo(false);
              }
            }}
          />
          {invalidEmail && (
            <div>
              <span className="text-xs font-medium text-red-500">
                {t('invalid_email')}
              </span>
            </div>
          )}
          {alertStatus ? (
            alertIsError ? (
              <div className="mx-4 my-2 text-center">
                <span className="text-xs font-medium text-red-500">
                  {t('forgot_pass_error')}
                </span>
              </div>
            ) : (
              <div className="flex items-center px-4 py-2 mx-4 my-2 text-center bg-green-200 rounded-lg">
                <img src="/images/check.png" width={30} className="mr-2" />
                <span className="block text-xs font-medium text-green-900">
                  {t('link_sent_to', { email: email })}
                  <br />
                  {t('check_your_email')}
                </span>
              </div>
            )
          ) : null}
          {emailSent && (
            <>
              <Input
                type="text"
                placeholder={t('code_placeholder')}
                onChange={(e) => {
                  setResetCode(e.target.value);
                }}
              />

              <Input
                type="password"
                iconLeftBool={true}
                iconLeft={IoLockClosedOutline}
                placeholder={t('forgot_password_placeholder')}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </>
          )}
          <div className="flex flex-col w-full px-4">
            <button
              disabled={disabled || locked}
              className={
                'w-full btn rounded-full my-4 uppercase text-white text-lg font-extrabold ' +
                (isShahidFlow
                  ? 'bg-gradient-to-r from-secondary via-tertiary to-primary text-white hover:bg-blend-darken disabled:bg-none'
                  : 'btn-primary')
              }
              onClick={emailSent ? resetPasswordConfirm : resetPassword}
            >
              {locked ? (
                <span className="loading loading-spinner loading-md "></span>
              ) : (
                t('send')
              )}
            </button>

            <div className="flex items-center w-full">
              <Link
                to={'/'}
                className="w-full mb-2 uppercase rounded-full btn btn-primary btn-outline text-md"
              >
                {t('go_back')}
              </Link>
            </div>
          </div>
        </ImageCard>

        {isAmdocsFlow ? <AmdocsStepsCounter /> : <StepsCounter />}

        <div className="z-50 toast toast-top toast-end">
          {_.map(toasts, ({ msg, type }, index) => (
            <Toast
              msg={msg}
              type={type}
              key={msg + index}
              index={index}
              total={toasts.length}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
}
