import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendGAEvent } from '../../../analyticsEvents';

export function renderDefaultActions(getButtonClass) {
  const { t } = useTranslation('homepage');

  return (
    <>
      <Link to={`/new-account`}>
        <button
          className={getButtonClass()}
          onClick={() => {
            sendGAEvent('create_account_clicked', {});
          }}
        >
          {t('create_an_account')}
        </button>
      </Link>
      <div className="flex items-center justify-center flex-1">
        <div className="uppercase divider">{t('homepage_or')}</div>
      </div>
      <Link to={`/login`}>
        <button
          className={getButtonClass(true)}
          onClick={() => {
            sendGAEvent('login_clicked', {});
          }}
        >
          {t('login_with_email')}
        </button>
      </Link>
    </>
  );
}
