import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import _ from 'lodash';

import Toast from '../components/Toast';
import LayoutBase from '../components/LayoutBase';
import StepsCounter from '../components/StepsCounter';
import axios from 'axios';
import api from '../service/axiosInstance';
import { useUser } from '../contexts/UserContext';
import { langs } from '../i18n/config';

export default function ReviewPaymentInfo() {
  const { t, i18n } = useTranslation('common');
  const { lang } = useParams();
  const { userData } = useUser();
  const [canceled, setCanceled] = useState(false);
  const [timedOutCancel, setTimedOutCancel] = useState(false);
  const [copied, setCopied] = useState(false);
  const [sbxToken, setSbxToken] = useState(0);
  const [toasts, setToasts] = useState([]);

  const [timeLeft, setTimeLeft] = useState(14 * 60 * 1000);

  const coinCode = 'XDB';
  const [sbx, setSBX] = useState(0);

  const prefix = langs.lastIndexOf(lang) >= 0 ? `/${lang}` : '';
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const [cookies, removeCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies['accessToken']) {
      navigate('/');
    }

    const handleBackButtonPress = () => {
      removeCookie('accessToken');
      removeCookie('refreshToken');
      navigate('/login');
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBackButtonPress);
  }, [cookies, navigate]);

  const navigateToDownload = () => {
    const completionUrl = prefix + '/download';
    navigate(completionUrl);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 0) {
          setTimedOutCancel(true);
          return 0;
        } else {
          return prevTimeLeft - 60000;
        }
      });
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const url = 'https://sandbox.coinbar.io/coinbar/api/v2/pay/gateway/quotes';
    const apiKey = process.env.REACT_APP_CBPAY_API_KEY;

    const headers = {
      'Content-Type': 'application/json',
      'CBPAY-API-KEY': apiKey,
    };

    axios
      .get(url, {
        headers: headers,
        params: {
          conversion_fiat: 'EUR',
        },
      })
      .then((response) => {
        if (response.data && response.data.result) {
          const eur2brl = 0.1832;
          const brl = 299.9;
          const eurFromBE = 0.0006893270519392329;
          const eurConverted = brl * eur2brl;

          const data = response.data.data;
          const btcObject = data.find((item) => item.code === coinCode);
          const conversionRate = btcObject.conversione_rate;
          let sbxConverted = 0;

          if (process.env.REACT_APP_ENV === 'production') {
            sbxConverted = (eurConverted / conversionRate).toFixed(6);
          } else {
            sbxConverted = (eurFromBE / conversionRate).toFixed(6);
          }

          const token = (conversionRate / eur2brl).toFixed(10);
          setSBX(sbxConverted);
          setSbxToken(token);
        } else {
          throw new Error('Error obtaining the Euro quotation');
        }
      })
      .catch((error) => {
        console.error({ error });
      });
  }, []);

  useEffect(() => {
    if (!canceled && !timedOutCancel) {
      const intervalId = setInterval(() => {
        api
          .post(
            '/api/v1/subscriptions/crypto/coinbar/check?transaction_id=' +
              userData.transaction_id,
            {}
          )
          .then((response) => {
            const { data } = response;
            const { status } = data;
            switch (status) {
              case 'PENDING':
              default:
                break;
              case 'SUCCESS':
                navigateToDownload();
                clearInterval(intervalId);
                break;
              case 'FAILED':
                setTimedOutCancel(true);
                clearInterval(intervalId);
                break;
            }
          })
          .catch((error) => {
            errorToasts(error);
          });
      }, 15000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [canceled, userData.transaction_id]);

  const errorToasts = (error) => {
    const detail = error.detail || error.response?.data.detail || error.message;
    let details = [];

    if (_.isArray(detail)) {
      details = _.map(detail, (item) => {
        const loc = _.last(item.loc);
        const msg = item.msg;
        return { msg: t(`${loc}: ${msg}`), type: 'error' };
      });
    } else {
      details = [{ msg: t(detail), type: 'error' }];
    }

    setToasts((state) => state.concat(details));
  };

  useEffect(() => {
    const handlePageReload = () => {
      navigate('/purchase');
    };

    window.addEventListener('load', handlePageReload);

    return () => {
      window.removeEventListener('load', handlePageReload);
    };
  }, [navigate]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(userData.payment_string)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch((error) => console.error('Could not copy text: ', error));
  };

  const minutes = Math.floor(timeLeft / 60000);

  const walletLink =
    '<a href="https://xdbchain.com/wallet/" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">XDB Wallet</a>';

  return (
    <LayoutBase bgImage={'/images/bg-target.png'}>
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center w-full lg:flex-row sm:w-4/5 lg:w-9/12 xl:w-3/5">
          <div className="flex flex-col items-center w-full md:w-4/5 lg:w-4/6">
            <div className="w-full p-4 m-4 rounded-lg bg-slate-100 drop-shadow">
              <h2 className="mb-4 text-lg font-bold text-center">
                {t('review_payment')}
              </h2>
              <div className="flex mb-2">
                <span className="block w-32 p-2 text-sm">
                  {t('requested_by')}:{' '}
                </span>
                <span className="block p-2 pl-4 overflow-hidden text-sm bg-white rounded-full drop-shadow w-72 text-slate-400">
                  {userData.name + ' ' + userData.surname}
                </span>
              </div>
              <div className="flex">
                <span className="block w-32 p-2 text-sm">{t('email')}:</span>
                <span className="block p-2 pl-4 overflow-auto text-sm bg-white rounded-full drop-shadow w-72 text-slate-400">
                  {userData.email}
                </span>
              </div>
            </div>
            <div className="flex flex-col px-6 py-4 bg-white rounded-lg drop-shadow">
              <span
                className="text-sm text-justify"
                dangerouslySetInnerHTML={{ __html: t('review_info') }}
              ></span>
            </div>
            <div className="w-full p-6 m-4 rounded-lg bg-slate-100 drop-shadow">
              <h2 className="text-lg font-bold text-center">
                {t('finalize_payment')}
              </h2>
              <span className="block mb-4 text-center">{t('scan_qrcode')}</span>

              <div className="flex flex-col items-center sm:flex-row justify-evenly">
                {!timedOutCancel && (
                  <div className="p-2 mr-2 bg-white rounded-xl drop-shadow">
                    <QRCode
                      value={userData.payment_string}
                      ecLevel="L"
                      enableCORS
                      size={150}
                    />
                  </div>
                )}
                <div>
                  <div>
                    <span className="block p-2 pl-4 m-2 bg-white rounded-full drop-shadow w-60">
                      <span className="text-sm text-gray-400">
                        {t('sbx_coin').toUpperCase()}:{' '}
                      </span>
                      <span className="text-sm text-gray-400">
                        {sbxToken} BRL
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="block p-2 pl-4 m-2 bg-white rounded-full drop-shadow w-60">
                      <span className="text-sm text-gray-400">
                        {t('blockchain')}: XDB chain
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="block p-2 pl-4 m-2 bg-white rounded-full drop-shadow w-60">
                      <span className="font-bold text-gray-500">
                        {t('cript')}:{' '}
                      </span>
                      <span className="font-semibold text-gray-500">{sbx}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <span className="inline-block p-1 truncate text-ellipsis whitespace-nowrap w-96 hover:overflow-x-auto">
                  {t('payment_details')}: {userData.payment_string}
                </span>
                <button
                  className="mx-2 btn btn-sm btn-outline"
                  onClick={copyToClipboard}
                >
                  {copied ? `${t('copied')}` : `${t('copy')}`}
                </button>
              </div>
            </div>
          </div>
          <div className="w-80 lg:w-auto">
            <div
              style={{
                width: 390 * 0.7,
                height: 374 * 0.7,
                marginBottom: -105,
                marginLeft: 25,
                backgroundImage: 'url(/images/chars/jumping.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                boxSizing: 'border-box',
                padding: 4,
              }}
            ></div>
            <div
              className="p-4 m-4 rounded-lg drop-shadow"
              style={{ backgroundColor: '#FFDE88' }}
            >
              <h2 className="text-center">
                {t('offer_expires')}: {minutes} min
              </h2>
              <div
                className="my-2"
                style={{
                  width: '100%',
                  height: 20,
                  backgroundImage: 'url(/images/bar-container.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  boxSizing: 'border-box',
                  padding: 4,
                }}
              >
                <div
                  className="drop-shadow progress-bar"
                  style={{
                    width: '100%',
                    height: 12,
                    backgroundColor: '#3E4595',
                    borderRadius: 12,
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      backgroundImage: 'url(/images/ufo.png)',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      boxSizing: 'border-box',
                      position: 'absolute',
                      right: -15,
                      top: -15,
                    }}
                  ></div>
                </div>
              </div>
              <div className="flex justify-between mb-4">
                <div className="w-2/3">
                  <div>
                    <span className="font-bold">{t('product')}</span>
                  </div>
                  <div>
                    <span className="text-sm">{t('months_access')} </span>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div>
                    <span className="font-bold">{t('price')}</span>
                  </div>
                  <div>
                    <span className="text-sm">R$ 299,90</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    setCanceled(true);
                  }}
                  className="text-white rounded-full btn btn-primary btn-wide btn-sm"
                >
                  {t('cancel')}
                </button>
              </div>
            </div>
            <div className="flex flex-col p-4 mx-4 bg-white rounded-lg drop-shadow">
              <span
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: t('how_to_scan').replace('{walletLink}', walletLink),
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <StepsCounter position={3} purchaseFlow={true} />
      </div>
      <div className="z-50 toast toast-top toast-end">
        {_.map(toasts, (msg, index) => (
          <Toast
            msg={msg}
            key={msg + index}
            index={index}
            total={toasts.length}
          />
        ))}
      </div>
      {canceled && (
        <>
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 transition-opacity bg-purple-500 bg-opacity-60"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <div className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex justify-end m-2 cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#6B7280"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                      onClick={() => {
                        setCanceled(false);
                        navigate('/purchase');
                      }}
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                  <div className="flex justify-center mt-4">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 140 140"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M55.2776 25.5C61.8209 14.1666 78.1791 14.1667 84.7224 25.5L115.899 79.5C122.443 90.8334 114.264 105 101.177 105H38.8231C25.7365 105 17.5574 90.8333 24.1007 79.5L55.2776 25.5Z"
                        fill="#FF6714"
                      />
                      <path
                        d="M71.6726 74.2373H68.326C65.2582 74.2373 63.6964 72.7069 63.6406 69.6462L62.6366 36.9984C62.5251 33.8243 64.0311 32.2373 67.1546 32.2373H72.8439C75.9675 32.2373 77.4735 33.8243 77.3619 36.9984L76.3579 69.6462C76.3022 72.7069 74.7404 74.2373 71.6726 74.2373ZM63.3896 88.3507V84.9499C63.3896 81.8325 64.9235 80.2737 67.9913 80.2737H72.0073C75.075 80.2737 76.6089 81.8325 76.6089 84.9499V88.3507C76.6089 91.4681 75.075 93.0268 72.0073 93.0268H67.9913C64.9235 93.0268 63.3896 91.4681 63.3896 88.3507Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <h2 className="text-center text-red-500">
                    {t('payment_cancelled')}
                  </h2>
                  <div className="m-4 text-center">
                    {t('payment_cancelled_error')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {timedOutCancel && (
        <>
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 transition-opacity bg-purple-500 bg-opacity-60"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <div className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex justify-end m-2 cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#6B7280"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                      onClick={() => {
                        setCanceled(false);
                        navigate('/purchase');
                      }}
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </div>
                  <div className="flex justify-center mt-4">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 140 140"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setTimedOutCancel(false);
                        navigate('/purchase');
                      }}
                    >
                      <path
                        d="M55.2776 25.5C61.8209  14.1666 78.1791 14.1667 84.7224 25.5L115.899 79.5C122.443 90.8334 114.264 105 101.177 105H38.8231C25.7365 105 17.5574 90.8333 24.1007 79.5L55.2776 25.5Z"
                        fill="#FF6714"
                      />
                      <path
                        d="M71.6726 74.2373H68.326C65.2582 74.2373 63.6964 72.7069 63.6406 69.6462L62.6366 36.9984C62.5251 33.8243 64.0311 32.2373 67.1546 32.2373H72.8439C75.9675 32.2373 77.4735 33.8243 77.3619 36.9984L76.3579 69.6462C76.3022 72.7069 74.7404 74.2373 71.6726 74.2373ZM63.3896 88.3507V84.9499C63.3896 81.8325 64.9235 80.2737 67.9913 80.2737H72.0073C75.075 80.2737 76.6089 81.8325 76.6089 84.9499V88.3507C76.6089 91.4681 75.075 93.0268 72.0073 93.0268H67.9913C64.9235 93.0268 63.3896 91.4681 63.3896 88.3507Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <h2 className="text-center text-red-500">
                    {t('payment_cancelled')}
                  </h2>
                  <div className="m-4 text-center">
                    {t('payment_timedout_error')}
                  </div>
                  <div className="flex justify-center m-6">
                    <a
                      href="https://playkids.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white rounded-full btn btn-primary btn-wide btn-sm"
                    >
                      {t('go_to_playkids')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </LayoutBase>
  );
}
